import React, { useState } from 'react'
import CRUDComponent from '../../../../component/common/CRUD-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import { Modal } from 'antd';

const History = () => {
    const [detailView, setDetailView] = useState(null)

    return (
        <>
            <Modal
                title={<p className='text-black'>Reason</p>}
                open={detailView !== null}
                centered
                onCancel={() => setDetailView(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <p className='text-black text-base'>{detailView?.notes}</p>
            </Modal>
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.CreditApproved.getAll,
                    extraQuery: { status: "2,3" },
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            console.log(el?.notes)
                            return {
                                ...el,
                                no: `WOOFF${el?.id?.toString().padStart(6, "0")}`,
                                activeDate: el?.activeDate && moment(el?.activeDate)?.format("DD-MM-YYYY HH:mm:ss"),
                                createdAt: el?.createdAt && moment(el?.createdAt)?.format("DD-MM-YYYY HH:mm:ss"),
                                email: el?.user?.email,
                                distributer: el?.distributer?.username,
                                amount: el?.credits,
                                notes: {
                                    id: el?.id,
                                    isDisable: !el?.notes && el?.notes !== "" ? true : false,
                                    onClick: () => {
                                        setDetailView({
                                            notes: el?.notes
                                        })
                                    },

                                },
                            };
                        });
                    },
                    column: CONSTANTS.TABLE.ALLHISTORY,
                }}
                UPDATE={{
                    API: CONSTANTS.API.CreditApproved.update,
                    message: "Updated Pricing successfully",
                    modaltitle: "Update Pricing",
                    modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                    // payloadModifier: (res) => res,
                }}
                // CREATE={{
                //     API: CONSTANTS.API.CreditApproved.create,
                //     message: "Created Pricing Successfully",
                //     modaltitle: "Add Pricing",
                //     modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                //     // payloadModifier: (res) => res,
                // }}
                // DELETE={{
                //     API: CONSTANTS.API.CreditApproved.delete,
                //     message: "Deleted Successfully",
                // }}
                isSearch
            /></>
    )
}

export default History