import { Breadcrumb } from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import BreadCrumb from "../../../component/common/breadCrumb";

const items=[
  {
    title: "App",
  },
  {
    title: "Plan",
  },
]

const Plan = () => {
  return (
    <>
        <BreadCrumb items={items} />

      {/* <Breadcrumb
        className="mt-5"
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            title: "Plan",
          },
        ]}
      /> */}

      <CRUDComponent
        GET={{
          API: CONSTANTS.API.Plan.getAll,
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => {
              return {
                ...el,
                no: el?.id,
              };
            });
          },
          column: CONSTANTS.TABLE.PLAN,
        }}
        UPDATE={{
          API: CONSTANTS.API.Plan.update,
          message: "Updated Plan successfully",
          modaltitle: "Update Plan",
          modalFields: CONSTANTS.FORM_FIELD.PLAN_MODAL,
          // payloadModifier: (res) => res,
        }}
        CREATE={{
          API: CONSTANTS.API.Plan.craete,
          message: "Created Plan Successfully",
          modaltitle: "Add Plan",
          modalFields: CONSTANTS.FORM_FIELD.PLAN_MODAL,
          // payloadModifier: (res) => res,
        }}
        DELETE={{
          API: CONSTANTS.API.Plan.delete,
          message: "Deleted Successfully",
        }}
        isSearch
      />
    </>
  );
};

export default Plan;
