import React from "react";
import { Column } from "@ant-design/plots";
import { each, groupBy } from "@antv/util";


function BarChart({ data = [] }) {
    // console.log(data)

    const config = {
        data,
        xField: 'month',
        yField: 'value',
        seriesField: 'name', // Ensure the bars are grouped by 'name'
        isGroup: true, // Enable grouping of bars
        groupField: 'group', // Group by a new field to control stacking behavior
        style: {
            inset: 2,
        },
        isStack: true,
        color: ({ name }) => {
            const item = data.find((d) => d.name === name);
            return item ? item.color : "#a2a2a2";
        },
        // yAxis: {
        //     title: {
        //         text: 'Monthly Rainfall (mm)',
        //         style: {
        //             fontSize: 12,
        //             fontWeight: 600,
        //         },
        //     },
        //     label: {
        //         formatter: (v) => `${v}`,
        //     },
        // },
        label: {
            position: "middle",
            layout: [
                {
                    type: "interval-adjust-position",
                },
                {
                    type: "interval-hide-overlap",
                },
                {
                    type: "adjust-color",
                },
            ],
            style: {
                fontSize: 10,
            },
        },

    };

    return <Column {...config} />;
}

export default BarChart;
