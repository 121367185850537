import { Navigate } from "react-router-dom";
import Error from "../page/error";
import UnAuthorize from "../page/unAuthorize";
import LogIn from "../page/user/login";
import Registration from "../page/user/Registration";
import AppLayout from "../component/layout/AppLayout";
import Dashboard from "../page/app/dashboard";
import { appRoot } from "./constant/CONSTANTS";
import ForgetPassword from "../page/user/Registration/forgetPass";
import User from "../page/app/user";
import UserId from "../page/app/user/UserId";
import ProjectId from "../page/app/user/UserId/ProjectId";
import ServiceId from "../page/app/user/UserId/ProjectId/ServiceId";
import EnvironmentId from "../page/app/user/UserId/ProjectId/ServiceId/EnvironmentId";
import DynamicUserLayout from "../page/app/user/DynamicUserLayout";
import Plan from "../page/app/plan";
import Language from "../page/app/language";
import Asset from "../page/app/asset";
import Feedback from "../page/app/feedback";
import UserList from "../page/app/userList";
import FeatureRequest from "../page/app/featureRequest";
import Setting from "../page/app/setting";
import Maintenance from "../page/app/maintence";
import PricingConfig from "../page/app/pricingConfig";
import BillingPage from "../page/app/billing";
import DistributerPatnerPage from "../page/app/distributePartner";
import CreditApprovePage from "../page/app/creditApproved";
import ManualBillCollect from "../page/app/manualBillCollect";
import BillAnalysis from "../page/app/billAnalysis";

export const ROUTES = {
  FORGOT_PASSWORD: "/forgot-password",
  DASHBOARD: "/dashboard",
  BILLANALYSIS: "/billAnalysis",
  USERS: "/users",
  PLAN: "/plan",
  LANGUAGE: "/language",
  ASSET: "/asset",
  FEEDBACK: "/feedback",
  CONNECTEDPROJECT: "/connectedproject",
  FEATUREREQUEST: "/request",
  MAINTENANCE: "/maintenance",
  SETTING: "/setting",
  PRICINGCONFIG: "/pricingconfig",
  BILLINGPAGE: "/billing",
  DISTRIBUTERPARTNER: "/distributor-partner",
  DISTRIBUTECREDIT: "/distribute-credit",
  APPROVEDCREDIT: "/approved-credit",
  BILLCOLLECT: "/billmanually",







  CLUBS: "/clubs",
  REQUEST: "/request",
  CLUB_REQUEST: "/request/:id",
  BOUNCERS: "/bouncers",
  VALETS: "/valets",
  FEED: "/feed",
  EVENTS: "/events",
  SETTING: "/setting",
  INVENTORY: "/inventory",
  INVENTORY_DETAILS: "/details",
  INVENTORY_MOVEHISTORY: "/movehistory",
  INVENTORY_EQUIPMENT: "/equipment",
  INVENTORY_EQUIPMENT_DETAILS: "/equipment/:eid/:id",
  INVENTORY_TRANSFER: "/transfer",
  INVENTORY_PURCHASE: "/purchase",
  EXPENSES: "/expenses",
  EXPENSES_DETAIL: "/detail",
  EXPENSES_VEHICAL: "/vehical",
  // MAINTENANCE: "/maintenance",
  EMPLOYEE: "/employee",
  EMPLOYEE_DETAILS: "/employee/:eid/:id",
  LOGBOOK: "/logBook",
  LOGBOOK_LOG: "/log",
  LOGBOOK_ABTMETER: "/ABTMeter",
  LOGBOOK_CLEANLOG: "/cleaningLog",
  PLANTDETAILS: "/plantDetails",
  PLANTDETAILS_DETAILS: "/details",
  PLANTDETAILS_OMChecklist: "/OMChecklist",
  PLANTDETAILS_SETTING: "/setting",


};

const LOGIN_ROUTES = [
  // {
  //   path: "/",
  //   element: <SidePage />,
  //   errorElement: <Error />,
  //   children: [
  //     {
  //       index: true,
  //       element: <LogIn />,
  //     },
  //     {
  //       path: "registration",
  //       element: <Registration />,
  //     },
  //   ],
  // },
  {
    index: true,
    element: <LogIn />,
  },
  {
    path: "registration",
    element: <Registration />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgetPassword />,
    errorElement: <Error />,
  },
];

const ALL_ROUTES = (appProps) => [
  ...LOGIN_ROUTES,

  {
    path: `${appRoot}`,
    element: <AppLayout {...appProps} />,
    children: [
      {
        index: true,
        element: <Navigate to={`${appRoot}${ROUTES.DASHBOARD}`} />,
      },
      {
        path: `${appRoot}${ROUTES.DASHBOARD}`,
        element: <Dashboard {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.BILLANALYSIS}`,
        element: <BillAnalysis {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.CONNECTEDPROJECT}`,
        element: <UserList {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.USERS}`,
        children: [
          { index: true, element: <User {...appProps} /> },
          {
            path: ":userId",
            element: <DynamicUserLayout {...appProps} />,
            children: [
              { index: true, element: <UserId {...appProps} /> },
              {
                path: ":projectId",
                children: [
                  { index: true, element: <ProjectId {...appProps} /> },
                  {
                    path: ":serviceId",
                    children: [
                      { index: true, element: <ServiceId {...appProps} /> },
                      { path: ":EnvironmentId", element: <EnvironmentId {...appProps} /> },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: `${appRoot}${ROUTES.PLAN}`,
        element: <Plan {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.LANGUAGE}`,
        element: <Language {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.ASSET}`,
        element: <Asset {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.FEEDBACK}`,
        element: <Feedback {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.FEATUREREQUEST}`,
        element: <FeatureRequest />,
      },
      {
        path: `${appRoot}${ROUTES.MAINTENANCE}`,
        element: <Maintenance {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.SETTING}`,
        element: <Setting {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.PRICINGCONFIG}`,
        element: <PricingConfig {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.BILLINGPAGE}`,
        element: <BillingPage {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.APPROVEDCREDIT}`,
        element: <CreditApprovePage {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.DISTRIBUTERPARTNER}`,
        element: <DistributerPatnerPage {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.DISTRIBUTECREDIT}`,
        element: <DistributerPatnerPage {...appProps} />,
      },
      {
        path: `${appRoot}${ROUTES.BILLCOLLECT}`,
        element: <ManualBillCollect {...appProps} />,
      },

      // {
      //   path: `${appRoot}${ROUTES.REQUEST}`,
      //   element: <Request />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.CLUB_REQUEST}`,
      //   element: <RequestAboutInfo />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.BOUNCERS}`,
      //   element: <Bouncers />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.VALETS}`,
      //   element: <Valets />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.FEED}`,
      //   element: <Feed />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.EVENTS}`,
      //   element: <Events />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.SETTING}`,
      //   element: <Setting />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}`,
      //   element: (
      //     <Navigate
      //       to={`${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`}
      //     />
      //   ),
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_DETAILS}`,
      //   element: <Inventory />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_MOVEHISTORY}`,
      //   element: <MoveHistory />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT}`,
      //   element: <Equipment />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_EQUIPMENT_DETAILS}`,
      //   element: <EquipmentDetailPage />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_TRANSFER}`,
      //   element: <Transfer />,
      // },
      // {
      //   path: `${appRoot}${ROUTES.INVENTORY}${ROUTES.INVENTORY_PURCHASE}`,
      //   element: <PurchaseRequest />,
      // },
      //   {
      //     path: `${appRoot}${ROUTES.EXPENSES}`,
      //     element: <Expenses />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.EXPENSES}`,
      //     element: (
      //       <Navigate to={appRoot + ROUTES.EXPENSES + ROUTES.EXPENSES_DETAIL} />
      //     ),
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.EXPENSES}${ROUTES.EXPENSES_DETAIL}`,
      //     element: <ExpanseDetails />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.EXPENSES}${ROUTES.EXPENSES_VEHICAL}`,
      //     element: <VehicalExpanse />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.MAINTENANCE}`,
      //     element: <Maintenance />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.EMPLOYEE}`,
      //     element: <Employee />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.EMPLOYEE_DETAILS}`,
      //     element: <EmployeeDetail />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.LOGBOOK}`,
      //     element: (
      //       <Navigate to={appRoot + ROUTES.LOGBOOK + ROUTES.LOGBOOK_LOG} />
      //     ),
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_LOG}`,
      //     element: <LogBookLog />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_ABTMETER}`,
      //     element: <LogBookABTMeter />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.LOGBOOK}${ROUTES.LOGBOOK_CLEANLOG}`,
      //     element: <LogBookCleanLog />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.PLANTDETAILS}`,
      //     element: (
      //       <Navigate
      //         to={appRoot + ROUTES.PLANTDETAILS + ROUTES.PLANTDETAILS_DETAILS}
      //       />
      //     ),
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_DETAILS}`,
      //     element: <PlantDetailsPage />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_OMChecklist}`,
      //     element: <PlantOMChecklist />,
      //   },
      //   {
      //     path: `${appRoot}${ROUTES.PLANTDETAILS}${ROUTES.PLANTDETAILS_SETTING}`,
      //     element: <PlantSetting />,
      //   },
    ],
  },
  {
    path: "/error",
    element: <Error />,
  },
  {
    path: "/unAuthorize",
    element: <UnAuthorize />,
  },
  {
    path: "*",
    element: <Navigate to="/error" />,
  },
];

export default ALL_ROUTES;
