import React from 'react'
import BreadCrumb from '../../../component/common/breadCrumb'
import CONSTANTS from '../../../util/constant/CONSTANTS'
import CRUDComponent from '../../../component/common/CRUD-Component'


const items = [
    {
        title: "App",
    },
    {
        title: "Billing",
    },
]

const BillingPage = ({ }) => {

    return (
        <>
            <BreadCrumb items={items} />

            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.PricingConfig.getAll,
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                            };
                        });
                    },
                    column: CONSTANTS.TABLE.PRICINGCONFIG,
                }}
                UPDATE={{
                    API: CONSTANTS.API.PricingConfig.update,
                    message: "Updated Pricing successfully",
                    modaltitle: "Update Pricing",
                    modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                    // payloadModifier: (res) => res,
                }}
                CREATE={{
                    API: CONSTANTS.API.PricingConfig.craete,
                    message: "Created Pricing Successfully",
                    modaltitle: "Add Pricing",
                    modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                    // payloadModifier: (res) => res,
                }}
                DELETE={{
                    API: CONSTANTS.API.PricingConfig.delete,
                    message: "Deleted Successfully",
                }}
                isSearch
            />
        </>
    )
}

export default BillingPage