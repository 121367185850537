import Eye from "../../asset/image/eye.png";
import { Button, Image, Input, Popconfirm, Rate, Select, Switch } from "antd";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from "@ant-design/icons";
import logo from "../../asset/logos/icon.svg";
import moment from "moment/moment";
import { getMaxUnit } from "../functions";

const alphanumericSort = (a, b, value) => {
  if (isNaN(parseInt(a[value]))) {
    return a[value]?.localeCompare(b[value]);
  }
  return a[value] - b[value];
};

const RenderActionButtons = (value) => {
  let ButtonsArray = [];
  if (value?.Delete) {
    ButtonsArray.push(RenderDeleteButton(value.Delete));
  }
  if (value?.Edit) {
    ButtonsArray.push(RenderEditButton(value.Edit));
  }
  if (value?.View) {
    ButtonsArray.push(RenderViewButton(value.View));
  }
  if (value?.Download) {
    ButtonsArray.push(RenderDownloadButton(value.Download));
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        // paddingRight: "5px",
      }}
    >
      {ButtonsArray?.map((el, i) => (
        <div key={i}>{el}</div>
      ))}
    </div>
  );
};

// Delete Button
const RenderDeleteButton = (value = {}) => {
  const { id = null, onClick = () => { } } = value;
  return (
    <Popconfirm title="Sure to delete?" onConfirm={() => onClick(id)}>
      <Button type="primary">
        {value?.name ? value?.name : <DeleteOutlined />}
      </Button>
      {/* <Button type="primary">{value?.name}</Button> */}
    </Popconfirm>
  );
};

// Delete button without confimation model
const RenderDeleteWithoutConfirmation = (value) => {
  return (
    <Popconfirm
      title="Sure to delete?"
      open={false}
      onOpenChange={() => value?.onClick(value.key)}
      onConfirm={() => value?.onClick(value.key)}
    >
      {value?.name ? (
        value?.name
      ) : (
        <DeleteOutlined style={{ color: "black" }} />
      )}
    </Popconfirm>
  );
};
// Download Button
const RenderDownloadButton = (value) => {
  return (
    <DownloadOutlined
      onClick={() => {
        window.open(value, "Download");
      }}
    />
  );
};

// Edit Button
const RenderEditButton = (value) => {
  const { id, onClick } = value;
  return (
    <Button
      type="primary"
      onClick={() => {
        onClick(id);
      }}
    >
      <EditOutlined />
    </Button>
  );
};

// Ratings
const RenderRating = (value = 0) => {
  // console.log(value);
  // const { ratings } = value;
  return (
    <div>
      {/* {ratings}&nbsp;< StarFilled /> */}
      <Rate
        disabled
        allowHalf
        value={value}
      // character={({ index }) => {
      //   if (index < ratings) {
      //     return <img src={stars} />;
      //   } else {
      //     return <img src={emptyStars} />;
      //   }
      // }}
      />
    </div>
  );
};

// View Image Button
const RenderViewImageButton = (value) => {
  return (
    <Image
      width={20}
      src={Eye}
      className="pointer"
      preview={{
        mask: false,
        src: value,
      }}
    />
  );
};

// Render Accept and Reject Button
const RenderAcceptRejectButton = (value) => {
  const { id, onAccept, onReject } = value;

  return (
    <div>
      <Popconfirm
        placement="top"
        title={"Are you sure to Accept this request?"}
        // description={description}
        onConfirm={() => {
          onAccept(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button style={{ backgroundColor: "#52c41a" }}>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        </Button>
      </Popconfirm>
      <Popconfirm
        placement="top"
        title={"Are you sure to Reject this request?"}
        // description={description}
        onConfirm={() => {
          onReject(id);
        }}
        okText="Yes"
        cancelText="No"
      >
        <Button className="ml-2 bg-red-600">
          <CloseCircleTwoTone twoToneColor="#dc2626" />
        </Button>
      </Popconfirm>
    </div>
  );
};

// Render View Button
const RenderViewButton = (data = {}) => {
  const { id = null, onClick = () => { }, isDisable = false } = data;

  return (
    <Button
      onClick={() => {
        onClick(id);
      }}
      disabled={isDisable}
      type="primary"
    >
      <EyeOutlined
      // onClick={() => {
      //   onClick(id);
      // }}
      />
    </Button>
  );
};

// Render Images
const ImageRanders = (value) => {
  let imageSrc = value;
  if (!imageSrc) {
    imageSrc = logo;
  }
  return (
    <div>
      <Image
        width={50}
        height={50}
        className="rounded-full object-cover"
        src={imageSrc}
        alt={value}
      />
    </div>
  );
};

// Toggle Button
const RenderToggleButton = (data = {}) => {
  const { value = null, id = null, onClick = () => { } } = data;
  return (
    <div>
      <Switch
        onChange={() => {
          onClick(id, !value);
        }}
        // checked={value}
        defaultChecked={value}
      />
    </div>
  );
};

// Checkbox render
const RenderCheckbox = (value) => {
  const { checked, id, onClick } = value;
  return (
    <div>
      <Input
        type="checkbox"
        checked={!checked}
        onChange={() => {
          onClick(id, checked);
        }}
      />
    </div>
  );
};

export const loginRoot = "/";
export const appRoot = "/app";
// export const ROUTES = {
//   FORGOT_PASSWORD: "/forgot-password",
//   DASHBOARD: "/dashboard",
//   INVENTORY: "/inventory",
//   INVENTORY_DETAILS: "/details",
//   INVENTORY_MOVEHISTORY: "/movehistory",
//   INVENTORY_EQUIPMENT: "/equipment",
//   INVENTORY_EQUIPMENT_DETAILS: "/equipment/:eid/:id",
//   INVENTORY_TRANSFER: "/transfer",
//   INVENTORY_PURCHASE: "/purchase",
//   EXPENSES: "/expenses",
//   EXPENSES_DETAIL: "/detail",
//   EXPENSES_VEHICAL: "/vehical",
//   MAINTENANCE: "/maintenance",
//   EMPLOYEE: "/employee",
//   EMPLOYEE_DETAILS: "/employee/:eid/:id",
//   LOGBOOK: "/logBook",
//   LOGBOOK_LOG: "/log",
//   LOGBOOK_ABTMETER: "/ABTMeter",
//   LOGBOOK_CLEANLOG: "/cleaningLog",
//   PLANTDETAILS: "/plantDetails",
//   PLANTDETAILS_DETAILS: "/details",
//   PLANTDETAILS_OMChecklist: "/OMChecklist",
//   PLANTDETAILS_SETTING: "/setting",
// };

const CONSTANTS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  GETMe: null,
  TABLE: {
    USERS: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Profile",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Mobile No.",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Referral Code",
        dataIndex: "myReferralCode",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Username",
        dataIndex: "userName",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Date of birth",
        dataIndex: "dob",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Private account",
        dataIndex: "checkbox",
        render: RenderCheckbox,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      // {
      //   title: "Block User",
      //   dataIndex: "toggle",
      //   render: RenderDeleteButton,
      //   // sorter: (a, b) => alphanumericSort(a, b, "no"),
      // },
      {
        title: "Block User",
        dataIndex: "toggle",
        render: RenderToggleButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      { title: "Action", dataIndex: "delete", render: RenderDeleteButton },
    ],

    USERS_BOOKING: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      ,
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Club Name",
        dataIndex: "club",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Event Name",
        dataIndex: "event",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Entries",
        dataIndex: "entries",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Total Amount",
        dataIndex: "total",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Address",
        dataIndex: "address",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Invoice",
        dataIndex: "invoice",
        render: RenderDownloadButton,
      },
    ],
    USERS_CAR_VALET: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      ,
      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Car Company",
        dataIndex: "company",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Car Model",
        dataIndex: "model",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Car Number",
        dataIndex: "number",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERS_CHAT_HISTORY: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Chat View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],
    USERS_POST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Image",
        dataIndex: "profilePic",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Date & Time",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Likes",
        dataIndex: "likes",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Caption",
        dataIndex: "caption",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "delete",
        render: RenderDeleteButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
    ],

    REQUEST: [
      {
        title: "NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Image",
        dataIndex: "image",
        render: ImageRanders,
        // sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      ,
      {
        title: "Club Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Owner Name",
        dataIndex: "ownerName",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Mobile",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Action",
        dataIndex: "action",
        render: RenderAcceptRejectButton,
      },
      // {
      //   title: "",
      //   dataIndex: "reject",
      //   render: RenderRejectButton,
      // },
    ],

    EMPLOYEE_DOCUMENT: [
      {
        title: "SR NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "dOCUMENT NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "DOCUMENT number",
        dataIndex: "number",
        sorter: (a, b) => alphanumericSort(a, b, "number"),
      },
      {
        title: "DOCUMENT type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      // {
      //   dataIndex: "preview",
      //   render: RenderViewButton,
      // },
      // {
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      // },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    SUPPLY_OUT_INVENTORY_TABLE_INNER: [
      {
        title: "Product",
        dataIndex: "productName",
        sorter: (a, b) => alphanumericSort(a, b, "productName"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Unit of Measure",
        dataIndex: "unit",
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
      },
      {
        dataIndex: "delete",
        render: RenderDeleteWithoutConfirmation,
      },
      // {
      //   render: (_, record) => randorMthor(_, record, handleDelete),
      // },
    ],
    EMPLOYEE_TIMEOFF: [
      {
        title: "SR NO.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        // render: (text) => <a>{text}</a>,
      },
      {
        title: "LEAVE TYPE",
        dataIndex: "leavetype",
        sorter: (a, b) => alphanumericSort(a, b, "leavetype"),
        render: (typeID) => {
          if (typeID === 3) {
            return <>Periods Leave</>;
          }
          if (typeID === 2) {
            return <>Sick Leave</>;
          }
          return <>DONE</>;
        },
      },
      {
        title: "LEAVE DATE",
        dataIndex: "from",
        sorter: (a, b) => moment(a.from).subtract(moment(b.from)),
      },
      {
        title: "REPORTING DATE",
        dataIndex: "to",
        sorter: (a, b) => moment(a.to).subtract(moment(b.to)),
      },
      {
        title: "DURATION",
        dataIndex: "duration",
        sorter: (a, b) => alphanumericSort(a, b, "duration"),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status?.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Active"}>Active </Select.Option>
              <Select.Option key={"Pending"}>Pending </Select.Option>
              <Select.Option key={"Rejected"}>Rejected</Select.Option>
            </Select>
          );
        },
      },
    ],
    INVENTORY: [
      {
        title: "Product",
        dataIndex: "productName",
        sorter: (a, b) => alphanumericSort(a, b, "productName"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Category",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "Unit",
        dataIndex: "unit",
        sorter: (a, b) => alphanumericSort(a, b, "unit"),
      },
      {
        title: "Cost",
        dataIndex: "cost",
        sorter: (a, b) => alphanumericSort(a, b, "cost"),
      },
      {
        title: "type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "serial Number",
        dataIndex: "trackingId",
        sorter: (a, b) => alphanumericSort(a, b, "trackingId"),
      },
    ],
    PURCHASE_REQUESTS: [
      {
        title: "Product Name",
        dataIndex: "product",
        sorter: (a, b) => alphanumericSort(a, b, "product"),
      },
      {
        title: "QTY",
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "UOM",
        dataIndex: "prn",
        sorter: (a, b) => alphanumericSort(a, b, "prn"),
      },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Pending"}>PENDING</Select.Option>
              <Select.Option key={"Delivered"}>Delivered</Select.Option>
              <Select.Option key={"Declined"}>Declined</Select.Option>
              <Select.Option key={"Approved"}>Approved</Select.Option>
            </Select>
          );
        },
      },
    ],
    TRANSFER: [
      {
        title: "Products",
        dataIndex: "product",
        sorter: (a, b) => alphanumericSort(a, b, "product"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "Serial No.",
        dataIndex: "srNumber",
        sorter: (a, b) => alphanumericSort(a, b, "srNumber"),
      },
      {
        title: "Usage Type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "receiever's name",
        dataIndex: "receiver",
        sorter: (a, b) => alphanumericSort(a, b, "receiver"),
      },
    ],
    MOVE_HISTORY: [
      {
        title: "Products",
        dataIndex: "product",
        sorter: (a, b) => alphanumericSort(a, b, "product"),
      },
      {
        title: "Qty",
        dataIndex: "quantity",
        sorter: (a, b) => alphanumericSort(a, b, "quantity"),
      },
      {
        title: "Category",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "receiever's name",
        dataIndex: "receiver",
        sorter: (a, b) => alphanumericSort(a, b, "receiver"),
      },
    ],
    MAINTENANCE_HISTORY: [
      {
        title: "TITLE",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "prioritiy",
        dataIndex: "prioriti",
        sorter: (a, b) => alphanumericSort(a, b, "prioriti"),
      },
      {
        title: "DATE",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "ASSIGNEE",
        dataIndex: "assignedId",
        sorter: (a, b) => alphanumericSort(a, b, "assignedId"),
      },
      {
        title: "MAINTANANCE TYPE",
        dataIndex: "comeToKnow",
        sorter: (a, b) => alphanumericSort(a, b, "comeToKnow"),
      },
      {
        title: "DURATION",
        dataIndex: "duration",
        sorter: (a, b) => alphanumericSort(a, b, "duration"),
      },
      {
        title: "createdBy",
        dataIndex: "createdBy",
        sorter: (a, b) => alphanumericSort(a, b, "createdBy"),
      },
    ],
    PLANT_DOCUMENT: [
      {
        title: "Documents #",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "DOCUMENT NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      // {
      //   title: "DOCUMENT number",
      //   dataIndex: "number",
      // },
      {
        title: "DOCUMENT type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    SETTING_NEW_CLIENT: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Client NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Plant Capacity",
        dataIndex: "plantCapacity",
        sorter: (a, b) => alphanumericSort(a, b, "plantCapacity"),
      },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    TECHNOLOGY: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: " NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Price",
        dataIndex: "price",
        sorter: (a, b) => alphanumericSort(a, b, "price"),
      },
    ],
    PROJECT: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
      },
    ],
    SERVICE: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "slack",
        dataIndex: "slack",
        sorter: (a, b) => alphanumericSort(a, b, "slack"),
      },
      {
        title: "description",
        dataIndex: "description",
        sorter: (a, b) => alphanumericSort(a, b, "description"),
      },
    ],
    ENVIRONMENT: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "NAME",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
    ],
    USER: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "View",
        dataIndex: "view",
        render: RenderViewButton,
      },
      {
        title: "View User",
        dataIndex: "viewUser",
        render: RenderViewButton,
      },
      {
        title: "Status",
        dataIndex: "status",
        // sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "Created At",
        dataIndex: "createdDate",
        sorter: (a, b) => alphanumericSort(a, b, "createdDate"),
        width: 180,
        render: (v) => {
          return <p className=" ">{`${moment(v)?.format("DD-MM-YYYY")} `} <br /> {` (${getMaxUnit(v)})`}</p>
        }
      },
      {
        title: "username",
        dataIndex: "username",
        sorter: (a, b) => alphanumericSort(a, b, "username"),
      },
      {
        title: "email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "isBlocked",
        dataIndex: "isBlocked",
        render: RenderToggleButton,
        // filters: [
        //   {
        //     text: "true",
        //     value: "true",
        //   },
        //   {
        //     text: "false",
        //     value: false,
        //   },
        // ],
        // onFilter: (value, record) => true,
        // filterSearch: true,
        // sorter: (a, b) => a.isBlocked < b?.isBlocked,
        // sorter: (a, b) => alphanumericSort(a, b, "isBlocked"),
      },
      {
        title: "isDomainActive",
        dataIndex: "isDomainCreated",
        render: RenderToggleButton,
      },
    ],
    LANGUAGE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
    ],
    ASSET: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Name",
        dataIndex: "fileName",
        sorter: (a, b) => alphanumericSort(a, b, "fileName"),
      },
      {
        title: "URL",
        dataIndex: "fileURL",
        sorter: (a, b) => alphanumericSort(a, b, "fileURL"),
      },
      {
        title: "Image",
        dataIndex: "fileURL",
        render: ImageRanders,
        sorter: (a, b) => alphanumericSort(a, b, "fileURL"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    BILLANALYSIS: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "User Name",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        // sorter: (a, b) => alphanumericSort(a, b, "email"),
      },

      {
        title: "Payment status",
        dataIndex: "isPaid",
        sorter: (a, b) => {
          console.log(a, b)
          return alphanumericSort(a, b, "paymentStatus")
        },
        filters: [
          {
            text: "Successful",
            value: "1",
          },
          {
            text: "Pending",
            value: "0",
          },
        ],
        filterMode: "tree",
        filterSearch: true,
      },
      {
        title: "Pending Amount",
        dataIndex: "amount",
        // sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Date",
        dataIndex: "date",
        sorter: (a, b) => alphanumericSort(a, b, "date"),
      },

    ],
    FEEDBACK: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "User Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Feedback",
        dataIndex: "feedback",
        sorter: (a, b) => alphanumericSort(a, b, "feedback"),
      },
      {
        title: "Rating",
        dataIndex: "rating",
        sorter: (a, b) => alphanumericSort(a, b, "rating"),
        render: RenderRating,
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    BILLMANUAL: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "User Name",
        dataIndex: "name",
        // sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        // sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Payment status",
        dataIndex: "isPaid",
        sorter: (a, b) => {
          console.log(a, b)
          return alphanumericSort(a, b, "paymentStatus")
        },
        filters: [
          {
            text: "Successful",
            value: "1",
          },
          {
            text: "Pending",
            value: "0",
          },
        ],
        filterMode: "tree",
        filterSearch: true,
      },
      {
        title: "Is Bill paid",
        dataIndex: "isbillpaid",
        // sorter: (a, b) => alphanumericSort(a, b, "isbillpaid"),
        render: (value) => {
          const { id, onAccept, isPaid } = value;
          return <Popconfirm
            placement="top"
            title={"Are you sure to Paid bill manually?"}
            // description={description}
            onConfirm={() => {
              onAccept(id);
            }}
            disabled={isPaid === "success"}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={isPaid === "success"} style={{ backgroundColor: isPaid === "success" ? "#f5f5f5" : "#52c41a" }}>
              <CheckCircleTwoTone twoToneColor={isPaid === "success" ? "#f5f5f5" : "#52c41a"} />
            </Button>
          </Popconfirm>
        },
      },
    ],
    MAINTENANCE: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Title",
        dataIndex: "title",
        sorter: (a, b) => alphanumericSort(a, b, "title"),
      },
      {
        title: "StartTime",
        dataIndex: "startTime",
        sorter: (a, b) => alphanumericSort(a, b, "startTime"),
      },
      {
        title: "EndTime",
        dataIndex: "endTime",
        sorter: (a, b) => alphanumericSort(a, b, "endTime"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    FEATUREREQUEST: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Title",
        dataIndex: "featureName",
        sorter: (a, b) => alphanumericSort(a, b, "featureName"),
      },
      {
        title: "Feedback",
        dataIndex: "featureDescription",
        sorter: (a, b) => alphanumericSort(a, b, "featureDescription"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    PLAN: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },

      {
        title: "Name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "Month Duration",
        dataIndex: "durationMonth",
        sorter: (a, b) => alphanumericSort(a, b, "durationMonth"),
      },
      {
        title: "Max Projects",
        dataIndex: "maxProjects",
        sorter: (a, b) => alphanumericSort(a, b, "maxProjects"),
      },
      {
        title: "Max Service",
        dataIndex: "maxService",
        sorter: (a, b) => alphanumericSort(a, b, "maxService"),
      },
      {
        title: "Max Environments",
        dataIndex: "maxEnvironments",
        sorter: (a, b) => alphanumericSort(a, b, "maxEnvironments"),
      },
      {
        title: "Max Error Logs",
        dataIndex: "maxErrorLogs",
        sorter: (a, b) => alphanumericSort(a, b, "maxErrorLogs"),
      },
      {
        title: "Max Month Error Logs",
        dataIndex: "maxMonthErrorLogs",
        sorter: (a, b) => alphanumericSort(a, b, "maxMonthErrorLogs"),
      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      // {
      //   title: "Action",
      //   dataIndex: "deleteTableRow",
      //   render: RenderDeleteButton,
      //   width: 80,
      // },
    ],
    DISTRIBUTER_PATNER: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
        windth: 20,
      },
      {
        title: "Name",
        dataIndex: "userName",
        windth: 120,

      },

      {
        title: "Email",
        dataIndex: "email",
        windth: 120,

      },
      {
        title: "Total Credit Quota",
        dataIndex: "totalCreditQuota",
        sorter: (a, b) => alphanumericSort(a, b, "totalCreditQuota"),
        windth: 120,

      },
      {
        title: "Max Credit Limit",
        dataIndex: "maxCreditLimit",
        sorter: (a, b) => alphanumericSort(a, b, "maxCreditLimit"),
        windth: 120,

      },
      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ]
    ,
    REQUESTHISTORY: [
      {
        title: "Coupon Code",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "User Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Provider",
        dataIndex: "distributer",
        sorter: (a, b) => alphanumericSort(a, b, "distributer"),
      },
      // {
      //   title: "Reason",
      //   dataIndex: "notes",
      //   sorter: (a, b) => alphanumericSort(a, b, "notes"),
      // },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
      },
      {
        title: "Action",
        dataIndex: "request",
        render: RenderAcceptRejectButton,
        width: 160,
      },

      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
    ],
    ALLHISTORY: [
      {
        title: "Coupon Code",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "User Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Provider",
        dataIndex: "distributer",
        sorter: (a, b) => alphanumericSort(a, b, "distributer"),
      },
      {
        title: "Reason",
        dataIndex: "notes",
        render: RenderViewButton,
        width: 90,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
      },
      {
        title: "Activated At",
        dataIndex: "activeDate",
        sorter: (a, b) => alphanumericSort(a, b, "activeDate"),
      },
      // {
      //   title: "Action",
      //   dataIndex: "request",
      //   render: RenderAcceptRejectButton,
      //   width: 160,
      // },

      // {
      //   title: "Action",
      //   dataIndex: "editTableRow",
      //   width: 80,
      //   render: RenderEditButton,
      // },
    ],
    PENDINGHISTORY: [
      {
        title: "Coupon Code",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "User Email",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      {
        title: "Provider",
        dataIndex: "distributer",
        sorter: (a, b) => alphanumericSort(a, b, "distributer"),
      },
      {
        title: "Reason",
        dataIndex: "notes",
        render: RenderViewButton,
        width: 90,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        sorter: (a, b) => alphanumericSort(a, b, "createdAt"),
      },
      // {
      //   title: "Activated At",
      //   dataIndex: "activeDate",
      //   sorter: (a, b) => alphanumericSort(a, b, "activeDate"),
      // },
    ],
    PRICINGCONFIG: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "CPU History",
        dataIndex: "cpuHistoryConst",
        sorter: (a, b) => alphanumericSort(a, b, "cpuHistoryConst"),
      },
      {
        title: "Server Activity Log",
        dataIndex: "serverActivityConst",
        sorter: (a, b) => alphanumericSort(a, b, "serverActivityConst"),
      },
      {
        title: "Crash Log",
        dataIndex: "errorLogConst",
        sorter: (a, b) => alphanumericSort(a, b, "errorLogConst"),
      },
      {
        title: "Custome Log",
        dataIndex: "customLogConst",
        sorter: (a, b) => alphanumericSort(a, b, "customLogConst"),
      },
      {
        title: "API Log",
        dataIndex: "apiBackupConst",
        sorter: (a, b) => alphanumericSort(a, b, "apiBackupConst"),
      },
      {
        title: "Data Backup Days",
        dataIndex: "backUpDaysConst",
        sorter: (a, b) => alphanumericSort(a, b, "backUpDaysConst"),
      },

      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],
    BILLING: [
      {
        title: "No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "CPU History",
        dataIndex: "cpuHistoryConst",
        sorter: (a, b) => alphanumericSort(a, b, "cpuHistoryConst"),
      },
      {
        title: "Server Activity Log",
        dataIndex: "serverActivityConst",
        sorter: (a, b) => alphanumericSort(a, b, "serverActivityConst"),
      },
      {
        title: "Crash Log",
        dataIndex: "errorLogConst",
        sorter: (a, b) => alphanumericSort(a, b, "errorLogConst"),
      },
      {
        title: "Custome Log",
        dataIndex: "customLogConst",
        sorter: (a, b) => alphanumericSort(a, b, "customLogConst"),
      },
      {
        title: "API Log",
        dataIndex: "apiBackupConst",
        sorter: (a, b) => alphanumericSort(a, b, "apiBackupConst"),
      },
      {
        title: "Data Backup Days",
        dataIndex: "backUpDaysConst",
        sorter: (a, b) => alphanumericSort(a, b, "backUpDaysConst"),
      },

      {
        title: "Action",
        dataIndex: "editTableRow",
        width: 80,
        render: RenderEditButton,
      },
      {
        title: "Action",
        dataIndex: "deleteTableRow",
        render: RenderDeleteButton,
        width: 80,
      },
    ],

    SETTING_CATEGORY_TABLE: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Category NAME",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      // {
      //   dataIndex: "delete",
      //   render: RenderDeleteButton,
      // },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    SETTING_VENDOR: [
      {
        title: "SN No.",
        dataIndex: "no",
        sorter: (a, b) => alphanumericSort(a, b, "no"),
      },
      {
        title: "Vendor name",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "Bussiness Name",
        dataIndex: "businessName",
        sorter: (a, b) => alphanumericSort(a, b, "businessName"),
      },
      {
        title: "Contact number",
        dataIndex: "mobile",
        sorter: (a, b) => alphanumericSort(a, b, "mobile"),
      },
      {
        title: "Email Address",
        dataIndex: "email",
        sorter: (a, b) => alphanumericSort(a, b, "email"),
      },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    SETTING_ROUTINE_CHECKUP: [
      {
        title: "Ctegory",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "sub Ctegory",
        dataIndex: "subCategory",
        sorter: (a, b) => alphanumericSort(a, b, "subCategory"),
      },
      {
        title: "activities",
        dataIndex: "activities",
        sorter: (a, b) => alphanumericSort(a, b, "activities"),
      },
      {
        title: "frequency",
        dataIndex: "frequency",
        sorter: (a, b) => alphanumericSort(a, b, "frequency"),
      },
      {
        title: "priority",
        dataIndex: "priority",
        sorter: (a, b) => alphanumericSort(a, b, "priority"),
      },
      { dataIndex: "multButton", render: RenderActionButtons },
    ],
    EVENT_LOG_TABLE: [
      {
        title: "event Details",
        dataIndex: "event",
        sorter: (a, b) => alphanumericSort(a, b, "event"),
      },
      {
        title: "event Type",
        dataIndex: "type",
        sorter: (a, b) => alphanumericSort(a, b, "type"),
      },
      {
        title: "from",
        dataIndex: "from",
        sorter: (a, b) => moment(a.from).subtract(moment(b.from)),
      },
      {
        title: "to",
        dataIndex: "to",
        sorter: (a, b) => moment(a.to).subtract(moment(b.to)),
      },
      {
        title: "attended By",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "status",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Pending"}>PENDING</Select.Option>
              <Select.Option key={"Completed"}>COMPLETED</Select.Option>
            </Select>
          );
        },
      },
    ],
    ABT_TABLE: [
      {
        title: "event Details",
        dataIndex: "abtNo",
        sorter: (a, b) => alphanumericSort(a, b, "abtNo"),
      },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
      {
        title: "import",
        dataIndex: "import",
        sorter: (a, b) => alphanumericSort(a, b, "import"),
      },
      {
        title: "export",
        dataIndex: "export",
        sorter: (a, b) => alphanumericSort(a, b, "export"),
      },
    ],
    CLEANING_TABLE: [
      {
        title: "zone",
        dataIndex: "zone",
        sorter: (a, b) => alphanumericSort(a, b, "zone"),
      },
      {
        title: "from Row",
        dataIndex: "fromRow",
        sorter: (a, b) => alphanumericSort(a, b, "fromRow"),
      },
      {
        title: "to Row",
        dataIndex: "toRow",
        sorter: (a, b) => alphanumericSort(a, b, "toRow"),
      },
      {
        title: "no. of modules",
        dataIndex: "modules",
        sorter: (a, b) => alphanumericSort(a, b, "modules"),
      },
      {
        title: "no. of rows",
        dataIndex: "rows",
        sorter: (a, b) => alphanumericSort(a, b, "rows"),
      },
      {
        title: "cleaning supervisor",
        dataIndex: "name",
        sorter: (a, b) => alphanumericSort(a, b, "name"),
      },
      {
        title: "total KWP",
        dataIndex: "totalKWP",
        sorter: (a, b) => alphanumericSort(a, b, "totalKWP"),
      },
      {
        title: "start Date",
        dataIndex: "startDate",
        sorter: (a, b) => moment(a.startDate).subtract(moment(b.startDate)),
      },
      {
        title: "end Date",
        dataIndex: "endDate",
        sorter: (a, b) => moment(a.endDate).subtract(moment(b.endDate)),
      },
    ],
    EXPANSE_TABLE: [
      {
        title: "party Name",
        dataIndex: "partyName",
        sorter: (a, b) => alphanumericSort(a, b, "partyName"),
      },
      {
        title: "expanse date",
        dataIndex: "expanseDate",
        sorter: (a, b) => moment(a.expanseDate).subtract(moment(b.expanseDate)),
      },
      {
        title: "Category",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "amount",
        dataIndex: "amount",
        sorter: (a, b) => alphanumericSort(a, b, "amount"),
      },
      {
        title: "payment mode",
        dataIndex: "payment",
        sorter: (a, b) => alphanumericSort(a, b, "payment"),
      },
      {
        title: "paid by",
        dataIndex: "tracking",
        sorter: (a, b) => alphanumericSort(a, b, "tracking"),
      },
      {
        title: "bill refrense",
        dataIndex: "billReference",
        sorter: (a, b) => alphanumericSort(a, b, "billReference"),
      },
      {
        title: "STATUS",
        dataIndex: "status",
        sorter: (a, b) => alphanumericSort(a, b, "status"),
        render: (status) => {
          return (
            <Select
              style={{ width: "100%" }}
              defaultValue={status?.value}
              onChange={(e) => status?.onChange(e)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Select.Option key={"Submit"}>Submit </Select.Option>
              <Select.Option key={"Approved"}>Approved </Select.Option>
              <Select.Option key={"Paid"}>Paid </Select.Option>
              <Select.Option key={"Rejected"}>Rejected</Select.Option>
            </Select>
          );
        },
      },
      // {
      //   title: "startDate",
      //   dataIndex: "startDate",
      //   sorter: (a, b) => moment(a.date).subtract(moment(b.startDate)),
      // },
      // {
      //   title: "endDate",
      //   dataIndex: "endDate",
      //   sorter: (a, b) => moment(a.date).subtract(moment(b.endDate)),
      // },
    ],
    VEHICAL_EXPANSE_TABLE: [
      {
        title: "vehicle type",
        dataIndex: "vehicle",
        sorter: (a, b) => alphanumericSort(a, b, "vehicle"),
      },
      {
        title: "vehicle number",
        dataIndex: "vehicleNumber",
        sorter: (a, b) => alphanumericSort(a, b, "vehicleNumber"),
      },
      {
        title: "category",
        dataIndex: "category",
        sorter: (a, b) => alphanumericSort(a, b, "category"),
      },
      {
        title: "cost",
        dataIndex: "coast",
        sorter: (a, b) => alphanumericSort(a, b, "coast"),
      },
      {
        title: "odometer reading",
        dataIndex: "odometerReading",
        sorter: (a, b) => alphanumericSort(a, b, "odometerReading"),
      },
      {
        title: "remarks",
        dataIndex: "remark",
        sorter: (a, b) => alphanumericSort(a, b, "remark"),
      },
      // {
      //   title: "total KWP",
      //   dataIndex: "totalKWP",
      //   sorter: (a, b) => alphanumericSort(a, b, "totalKWP"),
      // },
      // {
      //   title: "startDate",
      //   dataIndex: "startDate",
      //   sorter: (a, b) => moment(a.date).subtract(moment(b.startDate)),
      // },
      // {
      //   title: "STATUS",
      //   dataIndex: "status",
      //   sorter: (a, b) => alphanumericSort(a, b, "status"),
      //   render: (status) => {
      //     return (
      //       <Select
      //         style={{ width: "100%" }}
      //         defaultValue={status?.value}
      //         onChange={(e) => status?.onChange(e)}
      //         onClick={(e) => {
      //           e.stopPropagation();
      //         }}
      //       >
      //         <Select.Option key={"Submit"}>Submit </Select.Option>
      //         <Select.Option key={"Approved"}>Approved </Select.Option>
      //         <Select.Option key={"Paid"}>Paid </Select.Option>
      //         <Select.Option key={"Rejected"}>Rejected</Select.Option>
      //       </Select>
      //     );
      //   },
      // },
      {
        title: "date",
        dataIndex: "date",
        sorter: (a, b) => moment(a.date).subtract(moment(b.date)),
      },
    ],
  },
  FORM_FIELD: {
    LOGIN_PAGE_MODAL: [
      {
        no: 1,
        // Label: "User Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "User name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        // Label: "Password",
        name: "password",
        width: 24,
        id: "password",
        type: "password",
        placeholder: "Password",
        required: true,
      },
    ],
    LANGUAGE_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Language Name",
        required: true,
      },
    ],
    ASSET_CREATE_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "fileName",
        id: "fileName",
        type: "text",
        placeholder: "Enter Asset Name",
        required: true,
      },
      {
        no: 1,
        Label: "Image",
        name: "fileURL",
        id: "fileURL",
        type: "file",
        placeholder: "Enter Asset Image",
        required: true,
      },
    ],
    FEACTURE_REQUEST_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "featureName",
        id: "featureName",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Description",
        name: "featureDescription",
        id: "featureDescription",
        type: "textarea",
        placeholder: "Enter Description",
        required: false,
      },
    ],
    MAINTENANCE_MODAL: [
      {
        no: 1,
        Label: "Title",
        name: "title",
        id: "title",
        type: "text",
        placeholder: "Enter Title",
        required: true,
      },
      {
        no: 2,
        Label: "Duration",
        name: "duration",
        id: "duration",
        type: "daterange",
        placeholder: "Enter Duration",
        required: false,
        showTime: true
      },
    ],
    FEEDBACK_CREATE_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "userId",
        id: "userId",
        type: "select",
        placeholder: "Enter User Name",
        required: true,
      },
      {
        no: 1,
        Label: "Feedback",
        name: "feedback",
        id: "feedback",
        type: "text",
        placeholder: "Enter your Feedback",
        required: true,
      },
      {
        no: 2,
        Label: "Rating",
        name: "rating",
        id: "rating",
        type: "rating",
        placeholder: "Enter your Rating",
        required: true,
      },
    ],
    ASSET_UPDATE_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "fileName",
        id: "fileName",
        type: "text",
        placeholder: "Enter Asset Name",
        required: true,
      },
      {
        no: 1,
        Label: "Image",
        name: "fileURL",
        id: "fileURL",
        type: "fileWithPreview",
        placeholder: "Enter Asset Image",
        required: true,
      },
    ],
    USERS_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Your Name",
        required: true,
        labelwidth: 5,
      },

      {
        no: 2,
        Label: "Mobile Number",
        name: "mobile",
        id: "mobile",
        type: "mobile",
        placeholder: "Enter Mobile Number",
        required: true,
        labelwidth: 5,
        // width: 20,
      },
      {
        no: 3,
        Label: "Username",
        name: "user",
        id: "user",
        type: "text",
        placeholder: "Enter User Name",
        // required: true,
        labelwidth: 8,
        width: 8,
      },

      {
        no: 4,
        Label: "Date of Birth",
        name: "date",
        id: "date",
        type: "date",
        placeholder: "Enter Date of Birth",
        required: true,
        labelwidth: 8,
        width: 8,
      },
      {
        no: 5,
        Label: "Profile Picture",
        name: "profilePic",
        id: "profilePic",
        type: "file",
        placeholder: " ",
        required: true,
        labelwidth: 8,
        width: 8,
      },
    ],
    TECHNOLOGY_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Your Name",
        required: true,
      },
      {
        no: 1,
        Label: "Price",
        name: "price",
        id: "price",
        type: "number",
        placeholder: "Enter Your price",
        required: true,
      },
    ],
    NOTIFICATION_MODAL: [
      {
        no: 1,
        Label: "Notification Title",
        name: "notification",
        id: "notification",
        type: "text",
        placeholder: "Enter Notification Title",
        required: true,
      },

      {
        no: 2,
        Label: "Message",
        name: "message",
        id: "message",
        type: "text",
        placeholder: "Enter Message",
        required: true,
        // width: 24,
      },
    ],
    PLAN_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "name",
        id: "name",
        type: "text",
        placeholder: "Enter Plan Name",
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Amount",
        name: "amount",
        id: "amount",
        type: "number",
        placeholder: "Enter Amount",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Month Duration",
        name: "durationMonth",
        id: "durationMonth",
        type: "durationMonth",
        placeholder: "Enter Month Duration",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "Max Projects",
        name: "maxProjects",
        id: "maxProjects",
        type: "maxProjects",
        placeholder: "Enter Max Project",
        required: true,
        width: 12,
      },
      {
        no: 5,
        Label: "Max Service",
        name: "maxService",
        id: "maxService",
        type: "maxService",
        placeholder: "Enter Max Service",
        required: true,
        width: 12,
      },
      {
        no: 6,
        Label: "Max Environments",
        name: "maxEnvironments",
        id: "maxEnvironments",
        type: "maxEnvironments",
        placeholder: "Enter Max Environments",
        required: true,
        width: 12,
      },
      {
        no: 7,
        Label: "Max Error Logs",
        name: "maxErrorLogs",
        id: "maxErrorLogs",
        type: "maxErrorLogs",
        placeholder: "Enter Max Error Logs",
        required: true,
        width: 12,
      },
      {
        no: 8,
        Label: "Max Month Error Logs",
        name: "maxMonthErrorLogs",
        id: "maxMonthErrorLogs",
        type: "maxMonthErrorLogs",
        placeholder: "Enter Max Month Error Logs",
        required: true,
        width: 12,
      },
    ],
    DISTRIBUTER_ROLE_MODAL: [
      {
        no: 0,
        Label: "Distributer Name",
        name: "username",
        id: "username",
        type: "text",
        placeholder: "Enter Distributer name",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Email",
        name: "email",
        id: "email",
        type: "email",
        placeholder: "Enter email",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Total Credit Quota",
        name: "totalCreditQuota",
        id: "totalCreditQuota",
        type: "number",
        placeholder: "Enter Total Credit Quota",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Max Credit Limit",
        name: "maxCreditLimit",
        id: "maxCreditLimit",
        type: "number",
        placeholder: "Enter Max Credit Limit",
        required: true,
        width: 24,
      },

    ],
    DISTRIBUTER_EDIT_ROLE_MODAL: [
      {
        no: 1,
        Label: "Total Credit Quota",
        name: "totalCreditQuota",
        id: "totalCreditQuota",
        type: "number",
        placeholder: "Enter Total Credit Quota",
        required: true,
        width: 24,
      },
      {
        no: 1,
        Label: "Max Credit Limit",
        name: "maxCreditLimit",
        id: "maxCreditLimit",
        type: "number",
        placeholder: "Enter Max Credit Limit",
        required: true,
        width: 24,
      },

    ],
    PRICINGCONFIG_MODAL: [
      {
        no: 1,
        Label: "CPU History",
        name: "cpuHistoryConst",
        id: "cpuHistoryConst",
        type: "number",
        placeholder: "Enter cpuHistoryConst",
        required: true,
        width: 12,
        step: 0.001,
      },
      {
        no: 2,
        Label: "Server Activity Log",
        name: "serverActivityConst",
        id: "serverActivityConst",
        type: "number",
        placeholder: "Enter serverActivityConst",
        required: true,
        width: 12,
        step: 0.001,

      },
      {
        no: 3,
        Label: "Crash Log",
        name: "errorLogConst",
        id: "errorLogConst",
        type: "number",
        placeholder: "Enter errorLogConst",
        required: true,
        width: 12,
        step: 0.001,

      },
      {
        no: 4,
        Label: "Custome Log",
        name: "customLogConst",
        id: "customLogConst",
        type: "number",
        placeholder: "Enter customLogConst",
        required: true,
        width: 12,
        step: 0.001,

      },
      {
        no: 5,
        Label: "API Log",
        name: "apiBackupConst",
        id: "apiBackupConst",
        type: "number",
        placeholder: "Enter apiBackupConst",
        required: true,
        width: 12,
        step: 0.001,

      },


      {
        no: 6,
        Label: "Data Backup Days",
        name: "backUpDaysConst",
        id: "backUpDaysConst",
        type: "number",
        placeholder: "Enter backUpDaysConst",
        required: true,
        width: 12,
      },

    ],
    REJECT_REQUEST_MODAL: [
      {
        id: "notes",
        type: "textarea",
        placeholder: "Type here...",
        Label: "",
        name: "notes",
        required: true,
      },
    ],
    USER_PLAN_MODAL: [
      {
        no: 1,
        Label: "Max Projects",
        name: "maxProjects",
        id: "maxProjects",
        type: "number",
        placeholder: "Enter Max Project",
        required: true,
        width: 12,
      },
      {
        no: 2,
        Label: "Max Service",
        name: "maxService",
        id: "maxService",
        type: "number",
        placeholder: "Enter Max Service",
        required: true,
        width: 12,
      },
      {
        no: 3,
        Label: "Max Environments",
        name: "maxEnvironments",
        id: "maxEnvironments",
        type: "number",
        placeholder: "Enter Max Environments",
        required: true,
        width: 12,
      },
      {
        no: 4,
        Label: "Max ErrorLogs",
        name: "maxErrorLogs",
        id: "maxErrorLogs",
        type: "number",
        placeholder: "Enter Max Error Logs",
        required: true,
        width: 12,
      },
      {
        no: 5,
        Label: "Max Month Error Logs",
        name: "maxMonthErrorLogs",
        id: "maxMonthErrorLogs",
        type: "numbers",
        placeholder: "Enter Max Month Error Logs",
        required: true,
        width: 12,
      },
    ],
    USER_INFO_MODAL: [
      {
        no: 1,
        Label: "Name",
        name: "username",
        id: "username",
        type: "username",
        placeholder: "Enter Username",
        required: true,
        width: 24,
      },
      {
        no: 2,
        Label: "Organisation name",
        name: "organizationName",
        id: "organizationName",
        type: "organizationName",
        placeholder: "Enter Organisation name",
        required: false,
        width: 12,
      },
      {
        no: 3,
        Label: "Domain",
        name: "subDomain",
        id: "subDomain",
        type: "subDomain",
        placeholder: "Enter Domain",
        required: false,
        width: 12,
      },
      {
        no: 4,
        Label: "Organisation Fevicon",
        name: "organizationFavIcon",
        id: "organizationFavIcon",
        type: "file",
        placeholder: "Enter Organisation Fevicon",
        required: false,
        width: 12,
      },
      {
        no: 5,
        Label: "Organisation Image",
        name: "organizationImage",
        id: "organizationImage",
        type: "file",
        placeholder: "Enter Organisation Image",
        required: false,
        width: 12,
      },
      // {
      //   no: 5,
      //   Label: "Active plan",
      //   name: "activePlan",
      //   id: "activePlan",
      //   type: "activePlan",
      //   placeholder: "Enter Active plan",
      //   required: true,
      //   width: 12,
      // },
      // {
      //   no: 5,
      //   Label: "Organisation Fevicon",
      //   name: "organizationFavIcon",
      //   id: "organizationFavIcon",
      //   type: "organizationFavIcon",
      //   placeholder: "Enter Organisation Fevicon",
      //   required: true,
      //   width: 12,
      // },
    ],
  },

  API: {
    login: {
      type: "POST",
      endpoint: "/admin/login",
    },
    Analytics: {
      type: "GET",
      endpoint: "/admin/analytics",
    },
    signUp: { type: "POST", endpoint: "/user/signup" },
    getMe: {
      type: "GET",
      endpoint: "/admin/me",
    },
    getClubRequest: {
      type: "GET",
      endpoint: "/admins/clubs",
    },
    getOneClubRequest: {
      type: "GET",
      endpoint: "/admins/clubs/id/:requestId",
    },
    acceptOrRejectRequest: {
      type: "PATCH",
      endpoint: "/admins/clubs/kyc-status",
    },
    getUsers: {
      type: "GET",
      endpoint: "/admins/users/",
    },
    editUser: {
      type: "PATCH",
      endpoint: "/admins/users/block/:dataId",
    },
    deleteUsers: {
      type: "DELETE",
      endpoint: "/admins/users/id/:dataId",
    },
    postUserDashboard: {
      type: "POST",
      endpoint: "/admin/user/userToken/:userId",
    },
    Technology: {
      getAll: {
        type: "GET",
        endpoint: "/technology",
      },
      craete: {
        type: "POST",
        endpoint: "/technology",
      },
      update: {
        type: "PATCH",
        endpoint: "/technology/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/technology/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/technology/:dataId",
      },
    },
    Bill: {
      getAll: {
        type: "GET",
        endpoint: "/admin/bill",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/bill",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/bill/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/bill/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/bill/:dataId",
      },
      getAnalysis: {
        type: "GET",
        endpoint: "/admin/bill/analysis",
      }
    },
    User: {
      getAll: {
        type: "GET",
        endpoint: "/admin/user",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/user",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/user/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/user/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/user/:dataId",
      },
      createBillDone: {
        type: "POST",
        endpoint: "/admin/bill/payment-done",
      },

    },
    Project: {
      getAll: {
        type: "GET",
        endpoint: "/admin/project",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/project",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/project/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/project/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/project/:dataId",
      },
    },
    CreditApproved: {
      getAll: {
        type: "GET",
        endpoint: "/admin/credits",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/credits",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/credits/",
      },
      updateStatus: {
        type: "PATCH",
        endpoint: "/admin/credits/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/credits/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/credits/:dataId",
      },
    },
    Service: {
      getAll: {
        type: "GET",
        endpoint: "/admin/service",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/service",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/service/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/service/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/service/:dataId",
      },
    },
    Environment: {
      getAll: {
        type: "GET",
        endpoint: "/admin/serviceEnvironment",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/serviceEnvironment",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/serviceEnvironment/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/serviceEnvironment/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/serviceEnvironment/:dataId",
      },
    },

    UserPlan: {
      getAll: {
        type: "GET",
        endpoint: "/admin/userPlan",
      },

      update: {
        type: "PATCH",
        endpoint: "/admin/userPlan/:dataId",
      },

      getOne: {
        type: "GET",
        endpoint: "/admin/userPlan/:dataId",
      },
    },

    Plan: {
      getAll: {
        type: "GET",
        endpoint: "/admin/plan",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/plan",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/plan/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/plan/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/plan/",
      },
    },
    PricingConfig: {
      getAll: {
        type: "GET",
        endpoint: "/admin/priceConfig",
      },
      create: {
        type: "POST",
        endpoint: "/admin/priceConfig",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/priceConfig/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/priceConfig/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/priceConfig/",
      },
    },
    Billing: {
      getAll: {
        type: "GET",
        endpoint: "/admin/bill",
      },
      create: {
        type: "POST",
        endpoint: "/admin/bill",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/bill/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/bill/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/bill/",
      },
    },
    Distributer: {
      getAll: {
        type: "GET",
        endpoint: "/admin/creditDistribution",
      },
      create: {
        type: "POST",
        endpoint: "/admin/user/createDistributer",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/creditDistribution/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/user/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/user/",
      },
    },
    Language: {
      getAll: {
        type: "GET",
        endpoint: "/admin/language",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/language",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/language/:dataId",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/language/:dataId",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/language/:dataId",
      },
    },
    Asset: {
      getAll: {
        type: "GET",
        endpoint: "/admin/files",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/files",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/files/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/files/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/files/",
      },
    },
    FEEDBACK: {
      getAll: {
        type: "GET",
        endpoint: "/feedback",
      },
      craete: {
        type: "POST",
        endpoint: "/feedback",
      },
      update: {
        type: "PATCH",
        endpoint: "/feedback/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/feedback/",
      },
      getOne: {
        type: "GET",
        endpoint: "/feedback/",
      },
    },
    FEATUREREQUEST: {
      getAll: {
        type: "GET",
        endpoint: "/featureRequest",
      },
      craete: {
        type: "POST",
        endpoint: "/featureRequest",
      },
      update: {
        type: "PATCH",
        endpoint: "/featureRequest/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/featureRequest/",
      },
      getOne: {
        type: "GET",
        endpoint: "/featureRequest/",
      },
    },
    MAINTENANCE: {
      getAll: {
        type: "GET",
        endpoint: "/admin/maintenance",
      },
      craete: {
        type: "POST",
        endpoint: "/admin/maintenance",
      },
      update: {
        type: "PATCH",
        endpoint: "/admin/maintenance/",
      },
      delete: {
        type: "DELETE",
        endpoint: "/admin/maintenance/",
      },
      getOne: {
        type: "GET",
        endpoint: "/admin/maintenance/",
      },
    },
    SETTING: {
      getMaintenace: {
        type: "GET",
        endpoint: "/admin/settings/inMaintenance",
      },
      updateMaintenace: {
        type: "PATCH",
        endpoint: "/admin/settings/inMaintenance",
      },
      getPaymentConfig: {
        type: "GET",
        endpoint: "/admin/config",
      },
      updatePaymentConfig: {
        type: "PATCH",
        endpoint: "/admin/config/:dataId",
      }
    }
    //   driver: {
    //     type: "GET",
    //     endpoint: "/driver",
    //   },
  },

  TAB_MENU: {
    USER: [
      {
        no: 1,
        Label: "About Info",
        name: "about",
        id: "about",
      },
      {
        no: 2,
        Label: "Previous Bookings",
        name: "booking",
        id: "booking",
      },
      {
        no: 3,
        Label: "Car valet",
        name: "valet",
        id: "valet",
      },
      {
        no: 4,
        Label: "Chat History",
        name: "chat",
        id: "chat",
      },
      {
        no: 5,
        Label: "User Post",
        name: "post",
        id: "post",
      },
    ],
  },
};
export default CONSTANTS;
