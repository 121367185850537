import React from 'react'
import CRUDComponent from '../../../component/common/CRUD-Component';
import BreadCrumb from '../../../component/common/breadCrumb';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const items = [
    {
        title: "App",
    },
    {
        title: "Feature Request",
    },
]

const FeatureRequest = () => {
    return (
        <>
            <BreadCrumb items={items} />
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.FEATUREREQUEST.getAll,
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                                name: el?.user?.username,
                                rating: el?.rating ? el?.rating : 0,
                            };
                        });
                    },

                    column: CONSTANTS.TABLE.FEATUREREQUEST,
                }}
                UPDATE={{
                    API: CONSTANTS.API.FEATUREREQUEST.update,
                    message: "Updated Feature Request successfully",
                    isFormData: false,
                    modaltitle: "Update Feedback",
                    modalFields: CONSTANTS.FORM_FIELD.FEACTURE_REQUEST_MODAL,
                    // payloadModifier: (res) => res,
                }}
                // CREATE={{
                //   API: CONSTANTS.API.FEEDBACK.craete,
                //   message: "Created Feedback Successfully",
                //   modaltitle: "Add Feedback",
                //   isFormData: false,
                //   modalFields: CONSTANTS.FORM_FIELD.FEACTURE_REQUEST_MODAL,
                //   // payloadModifier: (res) => res,

                // }}
                DELETE={{
                    API: CONSTANTS.API.FEATUREREQUEST.delete,
                    message: "Deleted Feature Request Successfully",
                }}
                isSearch
            />
        </>
    )
}

export default FeatureRequest