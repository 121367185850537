import { Button, Card, Col, Row, Spin } from "antd";
import moment from "moment";
import React from "react";

const UserPlanInfo = (props) => {
  const { planDetails, setEditData, setFormIndex, userPlan, api } = props;
  return (
    <>
      <Card className={`px-5  min-h-full ${api?.isLoading ? "h-80" : ""}`}>
        <div className="font-medium text-2xl">User Plan Details</div>
        {api?.isLoading ? (
          <Spin className="mt-20" tip="Loading" size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Row className="mt-5">
            <Col span={24}>
              <div>
                {planDetails?.map((ele) => (
                  <Row key={ele?.id} className="font-medium mt-2 ">
                    <Col span={8} className="text-base">
                      {ele?.label} :
                    </Col>
                    <Col className="text-base">&nbsp;{ele?.value}</Col>
                  </Row>
                ))}
                <p className="mt-3">
                  Last Updated on{" "}
                  {moment(userPlan?.updatedAt).format("DD MMM, YYYY hh:mm A")}{" "}
                </p>
                <Button
                  onClick={() => {
                    setEditData(userPlan);
                    setFormIndex(1);
                  }}
                  className="mt-4"
                  type="primary"
                >
                  Edit Plan
                </Button>
              </div>
            </Col>
            {/* <Col className="-mt-5">
            <Button
              onClick={() => {
                setEditData(userInfo);
                setFormIndex(1);
              }}
              type="primary"
            >
              Edit Plan
            </Button>
          </Col> */}
          </Row>
        )}
      </Card>
    </>
  );
};

export default UserPlanInfo;
