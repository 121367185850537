import { Badge, Button, Card, Col, Row } from "antd";
import useHttp from "../../../hooks/use-http";
import React, { useEffect, useState } from "react";
import CONSTANTS, { appRoot } from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../util/Route";
import { apiGenerator } from "../../../util/functions";

const { Meta } = Card;
const UserList = (props) => {
    const [data, setData] = useState({});
    const api = useHttp();
    const navigate = useNavigate();

    const userProjectServiceEnviromentList = {};
    Object?.keys(props?.running_Enviroment)?.map((serviceEnvId) => {
        console.log(serviceEnvId)
        const detail = props?.running_Enviroment[serviceEnvId];
        const userId = detail?.userId;
        const projectId = detail?.projectID;
        const serviceToken =
            detail?.serviceToken;
        const serviceID = detail?.serviceID;
        const EnvName = detail?.EnvName;
        const projectName = detail?.projectName;
        const serviceName = detail?.serviceName;
        const userEmail = detail?.userEmail;

        if (userProjectServiceEnviromentList[userId]) {
            if (userProjectServiceEnviromentList[userId]?.project[projectId]) {
                if (userProjectServiceEnviromentList[userId]?.project[projectId]?.service[serviceID]) {
                    userProjectServiceEnviromentList[userId].project[projectId].service[serviceID].environment[serviceToken] = {
                        name: EnvName
                    }
                } else {
                    userProjectServiceEnviromentList[userId].project[projectId].service[serviceID] = {
                        name: serviceName,
                        environment: {
                            [serviceToken]: { name: EnvName }
                        }
                    }
                }
            } else {
                userProjectServiceEnviromentList[userId].project[projectId] = {
                    name: projectName,
                    service: {

                        [serviceID]: {
                            name: serviceName,
                            environment: {
                                [serviceToken]: { name: EnvName }
                            }
                        }
                    }
                }
            }
        } else {
            userProjectServiceEnviromentList[userId] = {
                name: userEmail,
                project: {
                    [projectId]: {
                        name: projectName,
                        service: {

                            [serviceID]: {
                                name: serviceName,
                                environment: {
                                    [serviceToken]: { name: EnvName }
                                }
                            }
                        }
                    },
                },

            };
        }
    });

    const DETAILS = [
        {
            title: "User",
            data: [
                {
                    title: "Total User",
                    count: data?.user?.totalUser || 0,
                    href: "/app/users",
                },
                {
                    title: "Total Active User",
                    count: data?.user?.totalActiveUser || 0,
                    href: "/app/users",
                },
                {
                    title: "Total Inactive User",
                    count: data?.user?.totalInactiveUser || 0,
                    href: "/app/users",
                },
                {
                    title: "Total Without Project User",
                    count: data?.user?.totalUserWithoutProject || 0,
                    href: "/app/users",
                },
            ],
        },
    ];

    useEffect(() => {
        api.sendRequest(CONSTANTS.API.Analytics, (res) => {
            setData(res?.data);
        });
    }, []);
    return (
        <Row gutter={[16, 16]} className="mt-6 mb-6">
            {DETAILS?.map((el, i) => (
                <>
                    <Col key={`main_user_${i}`} span={24} className="ml-4 flex items-center gap-2">
                        <p className="text-2xl font-semibold">
                            {el?.title}&nbsp;(
                            {Object?.keys(userProjectServiceEnviromentList)?.length})
                        </p>
                    </Col>
                    {Object?.keys(userProjectServiceEnviromentList)?.map((ell, i) => (
                        <Col span={4} xs={24} sm={12} md={12} lg={8} xl={8}>
                            <Card
                                hoverable
                                key={`userList_${i}`}
                                size="small"
                                className={`p-5 cursor-pointer`}

                            >
                                <div onClick={() => navigate(`${appRoot}${ROUTES.USERS}/${ell}`)}>
                                    <p className="text-lg">{userProjectServiceEnviromentList[ell]?.name}</p>
                                    {Object?.keys(userProjectServiceEnviromentList[ell]?.project)?.map((projectId) => {
                                        const projectDetail = userProjectServiceEnviromentList[ell]?.project[projectId];

                                        return <>
                                            <div className="flex gap-5 overflow-x-scroll mb-5">
                                                {/* <p className="mt-5">{projectDetail?.name}</p> */}

                                                {Object?.keys(projectDetail?.service)?.map((serviceId) => {
                                                    const serviceDetail = projectDetail?.service[serviceId];

                                                    return <Card
                                                        hoverable
                                                        bodyStyle={{
                                                            width: 290,
                                                            height: 130,
                                                        }} className="my-5 ml-1">
                                                        <Meta title={`${projectDetail?.name} / ${serviceDetail?.name}`} />
                                                        <div className="py-3">
                                                            {/* <p>{serviceDetail?.name}</p> */}
                                                            {Object?.keys(serviceDetail?.environment)?.map((environmentId) => {
                                                                const environmentDetail = serviceDetail?.environment[environmentId];

                                                                return <div>
                                                                    <Badge
                                                                        status="success"
                                                                        text={`${environmentDetail?.name}`}
                                                                    />
                                                                    {/* <p>{environmentDetail?.name}</p> */}
                                                                </div>
                                                            })}
                                                        </div>
                                                    </Card>
                                                })}
                                            </div>
                                        </>
                                    })}
                                </div>
                                <Button onClick={() => {
                                    api.sendRequest(apiGenerator(CONSTANTS.API.postUserDashboard, { userId: ell }), (res) => {
                                        const url = `${process.env.REACT_APP_USER_DASHBOARD_URL}?userToken=${res?.token}`;
                                        window.open(url);
                                    }, {})
                                }
                                } type="primary" className="z-10 absolute mt-2 bottom-2 right-2">View User Dashboard</Button>


                                {/* <div>
                <p className="font-semibold text-lg text-slate-400">
                  {el?.title}
                </p>
  
                <p className="text-xl font-medium">{el?.count}</p>
              </div> */}
                            </Card>
                        </Col>
                    ))}
                </>
            ))}
        </Row>
    );
};

export default UserList;
