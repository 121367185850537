import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../component/common/breadCrumb';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import useHttp from '../../../hooks/use-http';


const items = [
  {
    title: "App",
  },
  {
    title: "Feedback",
  },
]

const Feedback = () => {
  const [userDetail, setUserDetail] = useState([]);
  const api = useHttp();

  useEffect(() => {
    const field = CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL.find(
      (fil) => fil.id === "userId"
    );
    if (field?.option?.length) {
      return;
    }
    api.sendRequest(CONSTANTS.API.User.getAll, (res) => {

      setUserDetail(res?.data?.data)
      const options = res?.data?.rows?.filter(el => el?.username !== null)?.map((user) => ({
        id: user?.id,
        label: `${user?.username}`,
        value: user?.id,
      }));
      // console.log(options);
      field.option = options;

    });

  }, []);


  return (
    <>
      <BreadCrumb items={items} />
      {/* <Breadcrumb
            className="my-6"
            separator=" > "
            items={[
              {
                title: "App",
              },
              {
                title: "Asset",
              },
            ]}
          /> */}
      <CRUDComponent
        GET={{
          API: CONSTANTS.API.FEEDBACK.getAll,
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => {
              return {
                ...el,
                no: el?.id,
                name: el?.user?.username,
                rating: el?.rating ? el?.rating : 0,
              };
            });
          },

          column: CONSTANTS.TABLE.FEEDBACK,
        }}
        UPDATE={{
          API: CONSTANTS.API.FEEDBACK.update,
          message: "Updated Feedback successfully",
          isFormData: false,
          modaltitle: "Update Feedback",
          modalFields: CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL,
          // payloadModifier: (res) => res,
        }}
        // CREATE={{
        //   API: CONSTANTS.API.FEEDBACK.craete,
        //   message: "Created Feedback Successfully",
        //   modaltitle: "Add Feedback",
        //   isFormData: false,
        //   modalFields: CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL,
        //   // payloadModifier: (res) => res,

        // }}
        DELETE={{
          API: CONSTANTS.API.FEEDBACK.delete,
          message: "Deleted Feedback Successfully",
        }}
        isSearch
      />
    </>
  );
}

export default Feedback