import { Breadcrumb } from "antd";
import React from "react";
import CRUDComponent from "../../../component/common/CRUD-Component";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import BreadCrumb from "../../../component/common/breadCrumb";

const items=[
  {
    title: "App",
  },
  {
    title: "Asset",
  },
]

const Asset = () => {
  return (
    <>
    <BreadCrumb items={items} />
      {/* <Breadcrumb
        className="my-6"
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            title: "Asset",
          },
        ]}
      /> */}
      <CRUDComponent
        GET={{
          API: CONSTANTS.API.Asset.getAll,
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => {
              return {
                ...el,
                no: el?.id,
              };
            });
          },
          column: CONSTANTS.TABLE.ASSET,
        }}
        UPDATE={{
          API: CONSTANTS.API.Asset.update,
          message: "Updated Asset successfully",
          isFormData: true,
          modaltitle: "Update Asset",
          modalFields: CONSTANTS.FORM_FIELD.ASSET_UPDATE_MODAL,
          // payloadModifier: (res) => res,
        }}
        CREATE={{
          API: CONSTANTS.API.Asset.craete,
          message: "Created Asset Successfully",
          modaltitle: "Add Asset",
          isFormData: true,
          modalFields: CONSTANTS.FORM_FIELD.ASSET_CREATE_MODAL,
          // payloadModifier: (res) => res,
        }}
        DELETE={{
          API: CONSTANTS.API.Asset.delete,
          message: "Deleted Asset Successfully",
        }}
        isSearch
      />
    </>
  );
};

export default Asset;
