import { Card, Col, Row, Switch, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../component/common/breadCrumb'
import useHttp from "../../../hooks/use-http";
import CONSTANTS from '../../../util/constant/CONSTANTS';
import { apiGenerator } from '../../../util/functions';
const items = [
    {
        title: "App",
    },
    {
        title: "Setting",
    },
]

const Setting = () => {

    const [isMaintenance, setIsMaintence] = useState("0");
    const [ispayment, setIsPayment] = useState({});

    const api = useHttp();


    useEffect(() => {
        api.sendRequest(CONSTANTS.API.SETTING.getMaintenace, (res) => {
            setIsMaintence(res?.data?.value)
        })
        api.sendRequest(CONSTANTS.API.SETTING.getPaymentConfig, (res) => {
            const obj = {};
            res?.data?.rows?.map((el) => {
                obj[el?.name] = el?.value;
            })
            setIsPayment(obj)
        })

    }, []);

    const onClick = (value) => {
        const payload = { value }
        api.sendRequest(CONSTANTS.API.SETTING.updateMaintenace, (res) => {
            setIsMaintence(pr => {
                if (pr === "0") return "1";
                if (pr === "1") return "0";
            })
        }, payload)
    }

    const onPaymentClick = (value, key) => {
        const payload = { value }
        api.sendRequest(apiGenerator(CONSTANTS.API.SETTING.updatePaymentConfig, {
            dataId: key
        }), (res) => {
            notification.success({ message: "Payment gateway updated" })
            setIsPayment(pr => {

                return {
                    ...pr,
                    [key]: value
                }

            })
        }, payload)
    }
    return (
        <>
            <BreadCrumb items={items} />
            <Card className='my-6'>
                <Row>
                    <Col className='mb-4  items-center gap-4' span={24}><p className='text-xl mb-4'>Maintenance</p>
                        <Switch

                            onChange={() => {
                                onClick(isMaintenance === "0" ? "1" : "0");
                            }}
                            checked={isMaintenance === "0" ? false : true}
                        // defaultChecked={isMaintenance === "0" ? false : true}
                        />
                    </Col>
                    <Col className='mb-4  items-center gap-4' span={24}><p className='text-xl mb-4'>Payment gateway</p>
                        <Row className='gap-5'>
                            <Col>
                                <p className='text-lg mb-4'>Razorpay</p>
                                <Switch

                                    onChange={() => {
                                        onPaymentClick(ispayment?.razorpay === "0" ? "1" : "0", "razorpay");
                                    }}
                                    checked={ispayment?.razorpay === "1" ? true : false}
                                // defaultChecked={isMaintenance === "0" ? false : true}
                                />
                            </Col>
                            <Col>
                                <p className='text-lg mb-4'>Stripe</p>
                                <Switch
                                    onChange={() => {
                                        onPaymentClick(ispayment?.stripe === "0" ? "1" : "0", "stripe");
                                    }}
                                    checked={ispayment?.stripe === "1" ? true : false}
                                // defaultChecked={isMaintenance === "0" ? false : true}
                                />
                            </Col>
                        </Row>

                    </Col>


                </Row>

            </Card>
        </>
    )
}

export default Setting