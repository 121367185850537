import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import CONSTANTS from "../../../../util/constant/CONSTANTS";
import CRUDComponent from "../../../../component/common/CRUD-Component";
import { Breadcrumb, Card, Row, Spin } from "antd";
import useHttp from "../../../../hooks/use-http";
import ProjectCards from "../../../../component/card/projectCards";
import BreadCrumb from "../../../../component/common/breadCrumb";



const UserId = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const api = useHttp();
  const items = [
    {
      title: "App",
    },
    {
      // href: `/app/users`,
      title: <p className="cursor-pointer px-1 hover:bg-[#8080801f]  rounded-sm" onClick={() => navigate(`/app/users`)}>Users</p>,
    },

    {
      title: "Project",
    },
  ]
  // const userInfo = {};

  return (
    <>
      <BreadCrumb items={items} />

      {/* <Breadcrumb
        className="mb-6"
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            href: `/app/users/`,
            title: "Users",
          },

          {
            title: "Project",
          },
        ]}
      /> */}

      {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div>
          <Row gutter={[12, 12]} className="my-4">
            <ProjectCards />
          </Row>
          <Card>
            <div className="font-medium text-2xl">Project</div>
            <CRUDComponent
              GET={{
                API: CONSTANTS.API.Project.getAll,
                extraQuery: { userId: userId },
                DataModifier: (res, API, Setrefresh) => {
                  return res?.map((el) => ({
                    ...el,
                    no: el?.id,
                    view: {
                      onClick: () => {
                        navigate(`/app/users/${userId}/${el?.id}`);
                      },
                      id: el?.id,
                    },
                  }));
                },
                column: CONSTANTS.TABLE.PROJECT,
              }}
              // UPDATE={{
              //   API: CONSTANTS.API.Project.update,
              //   message: "Updated Project successfully",
              //   modaltitle: "Update Project",
              //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
              //   // payloadModifier: (res) => res,
              // }}
              // CREATE={{
              //   API: CONSTANTS.API.Project.craete,
              //   message: "Craeyed Project successfully",
              //   modaltitle: "add Project",
              //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
              //   // payloadModifier: (res) => res,
              // }}
              //   DELETE={{
              //     API: CONSTANTS.API.Project.delete,
              //     message: "Dleted Successfully",
              //   }}
              FILTERSORTKEY={
                {
                  //   isBlocked: "$FCM$",
                }
              }
              isSearch
            />
          </Card>
        </div>
      )}

      {/* )} */}
      {/* <Tabs
        defaultActiveKey="1"
        type="card"
        items={[
          {
            label: `Project`,
            key: 1,
            children: (
              <Card>
                <CRUDComponent
                  GET={{
                    API: CONSTANTS.API.Project.getAll,
                    extraQuery: { userId: userId },
                    DataModifier: (res, API, Setrefresh) => {
                      return res?.map((el) => ({
                        ...el,
                        view: {
                          onClick: () => {
                            navigate(`/app/users/${userId}/${el?.id}`);
                          },
                          id: el?.id,
                        },
                      }));
                    },
                    column: CONSTANTS.TABLE.PROJECT,
                  }}
                  // UPDATE={{
                  //   API: CONSTANTS.API.Project.update,
                  //   message: "Updated Project successfully",
                  //   modaltitle: "Update Project",
                  //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
                  //   // payloadModifier: (res) => res,
                  // }}
                  // CREATE={{
                  //   API: CONSTANTS.API.Project.craete,
                  //   message: "Craeyed Project successfully",
                  //   modaltitle: "add Project",
                  //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
                  //   // payloadModifier: (res) => res,
                  // }}
                  //   DELETE={{
                  //     API: CONSTANTS.API.Project.delete,
                  //     message: "Dleted Successfully",
                  //   }}
                  FILTERSORTKEY={
                    {
                      //   isBlocked: "$FCM$",
                    }
                  }
                />
              </Card>
            ),
          },
          // {
          //   label: `User Plan`,
          //   key: 2,
          //   children: <Plan />,
          // },
          // {
          //   label: `Usage`,
          //   key: 3,
          //   children: <Card>`Content of card tab 3` </Card>,
          // },
        ]}
      /> */}
    </>
  );
};

export default UserId;
