import React from 'react'
import BreadCrumb from '../../../component/common/breadCrumb';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';

const DistributerPatnerPage = () => {
    const items = [
        {
            title: "App",
        },
        {
            title: "Distributer",
        },
    ]
    return (
        <>
            <BreadCrumb items={items} />

            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.Distributer.getAll,
                    // extraQuery: { role: "Distributer" },
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                                email: el?.distributer?.email,
                                userName: el?.distributer?.username,
                                totalCreditQuota: el?.totalCreditQuota,
                                maxCreditLimit: el?.maxCreditLimit,
                            };
                        });
                    },
                    column: CONSTANTS.TABLE.DISTRIBUTER_PATNER,
                }}
                UPDATE={{
                    API: CONSTANTS.API.Distributer.update,
                    message: "Updated Distributer successfully",
                    modaltitle: "Update Pricing",
                    modalFields: CONSTANTS.FORM_FIELD.DISTRIBUTER_EDIT_ROLE_MODAL,
                    payloadModifier: (res) => res,
                }}
                CREATE={{
                    API: CONSTANTS.API.Distributer.create,
                    message: "Created Distributer Successfully",
                    modaltitle: "Add Distributer",
                    modalFields: CONSTANTS.FORM_FIELD.DISTRIBUTER_ROLE_MODAL,
                    // payloadModifier: (res) => res,
                }}
                DELETE={{
                    API: CONSTANTS.API.Distributer.delete,
                    message: "Deleted Successfully",
                }}
                isSearch
            />
        </>
    )
}

export default DistributerPatnerPage;