import { Card, Tabs } from 'antd'
import React from 'react'
import Request from './tab/request'
import History from './tab/history'
import Pending from './tab/pending'
import BreadCrumb from '../../../component/common/breadCrumb'

const CreditApprovePage = () => {
    const items = [
        {
            title: "App",
        },
        {
            title: "Approved Credit",
        },
    ]
    return (
        <>
            <BreadCrumb items={items} />
            <Card className='mt-10'>
                <Tabs
                    defaultActiveKey="1"
                    items={[
                        {
                            label: 'Request',
                            key: '1',
                            children: <Request />,
                        },
                        {
                            label: 'History',
                            key: '2',
                            children: <History />,
                        },
                        {
                            label: 'Pending',
                            key: '3',
                            children: <Pending />,
                        },

                    ]}
                />
            </Card></>
    )
}

export default CreditApprovePage