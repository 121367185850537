import { Card, Col, Row, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import UserPlanInfo from "./userPlanInfo";
import UserInfoCard from "./userInfoCard";
import ModalFormCreator from "../common/ModalFormCreator";
import { useParams } from "react-router-dom";
import { apiGenerator } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";
import useHttp from "../../hooks/use-http";
import moment from "moment";
import { payloadGenerator } from "../common/CRUD-Component";

const ProjectCards = (props) => {
  const { } = props;
  const { userId } = useParams();
  const api = useHttp();
  const [userInfo, setUserInfo] = useState({});
  const [userPlan, setUserPlan] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [editData, setEditData] = useState(null);
  const [formIndex, setFormIndex] = useState(-1);

  const userInfoDetails = [
    {
      id: 1,
      label: "Name",
      value: userInfo?.username || "-",
    },
    {
      id: 2,
      label: "Organisation name",
      value: userInfo?.organizationName || "-",
    },
    {
      id: 3,
      label: "Email",
      value: userInfo?.email || "-",
    },
    {
      id: 4,
      label: "Domain",
      value: userInfo?.domain || "-",
    },
    {
      id: 5,
      label: "Active plan",
      value: userInfo?.userInfos?.[0]?.plan?.name || "-",
    },
    {
      id: 6,
      label: "Account Created Date",
      value: userInfo?.createdAt
        ? moment(userInfo?.createdAt).format("DD MMM, YYYY hh:mm A")
        : "-",
    },
  ];
  const planDetails = [
    {
      id: 1,
      label: "Max Projects",
      value: userPlan?.maxProjects || "-",
    },
    {
      id: 2,
      label: "Max Service",
      value: userPlan?.maxService || "-",
    },
    {
      id: 3,
      label: "Max Environments",
      value: userPlan?.maxEnvironments || "-",
    },
    {
      id: 4,
      label: "Max Error Logs",
      value: userPlan?.maxErrorLogs || "-",
    },
    {
      id: 5,
      label: "Max Month Error Logs",
      value: userPlan?.maxMonthErrorLogs || "-",
    },
    {
      id: 6,
      label: "Plan Expiry Date",
      value: userPlan?.expiryDate
        ? moment(userPlan?.expiryDate).format("DD MMM, YYYY")
        : "-",
    },
  ];
  const userCardList = [
    { title: "Environment", count: userInfo?.environmentCount || 0 },
    { title: "Service", count: userInfo?.serviceCount || 0 },
    { title: "Project", count: userInfo?.projectCount || 0 },
    { title: "Logs", count: userInfo?.logCount || 0 },
  ];

  const editUserInfoHandler = (value) => {
    const EDIT_USER_INFO_API = apiGenerator(CONSTANTS.API.User.update, {
      dataId: userId,
    });
    let payload = payloadGenerator(
      value,
      CONSTANTS.FORM_FIELD?.USER_INFO_MODAL,
      true
    );

    api.sendRequest(
      EDIT_USER_INFO_API,
      (res) => {
        setEditData(null);
        // setFormIndex(-1)
        setRefresh((prev) => !prev);
      },
      payload
    );
  };
  const editPlanHandler = (value) => {
    const EDIT_USER_PLAN_API = apiGenerator(CONSTANTS.API.UserPlan.update, {
      dataId: userId,
    });

    api.sendRequest(
      EDIT_USER_PLAN_API,
      (res) => {
        setEditData(null);
        // setFormIndex(-1)
        setRefresh((prev) => !prev);
      },
      value
    );
  };

  useEffect(() => {
    if (formIndex == -1) return;

    if (formIndex == 0) {
      const GET_USER_INFO_API = apiGenerator(CONSTANTS.API.User.getOne, {
        dataId: userId,
      });
      // console.log(GET_USER_INFO_API);
      api.sendRequest(GET_USER_INFO_API, (res) => {
        // console.log(res?.data);
        setFormIndex(-1);
        setUserInfo(res?.data);
      });
    } else if (formIndex == 1) {
      const GET_USER_PLAN_API = apiGenerator(CONSTANTS.API.UserPlan.getOne, {
        dataId: userId,
      });
      // console.log(GET_USER_PLAN_API);
      api.sendRequest(GET_USER_PLAN_API, (res) => {
        // console.log(res?.data);
        setFormIndex(-1);
        setUserPlan(res?.data);
      });
    }
  }, [refresh]);

  useEffect(() => {
    const GET_USER_INFO_API = apiGenerator(CONSTANTS.API.User.getOne, {
      dataId: userId,
    });
    // console.log(GET_USER_PLAN_API);
    api.sendRequest(GET_USER_INFO_API, (res) => {
      // console.log(res?.data);
      setUserInfo(res?.data);
    });
    const GET_USER_PLAN_API = apiGenerator(CONSTANTS.API.UserPlan.getOne, {
      dataId: userId,
    });
    // console.log(GET_USER_PLAN_API);
    api.sendRequest(GET_USER_PLAN_API, (res) => {
      // console.log(res?.data);
      setUserPlan(res?.data);
    });
  }, []);

  return (
    <>
      <Col xs={24} sm={24} md={24} lg={12} className=" min-h-full">
        <UserInfoCard
          userInfo={userInfo}
          userInfoDetails={userInfoDetails}
          setEditData={setEditData}
          setFormIndex={setFormIndex}
          api={api}
        />
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} className=" min-h-full">
        <UserPlanInfo
          userPlan={userPlan}
          planDetails={planDetails}
          setEditData={setEditData}
          setFormIndex={setFormIndex}
          api={api}
        />
      </Col>
      {userCardList?.map((el, index) => (
        <Col xs={24} sm={12} md={6} lg={6} key={`user_detail_${index}`}>
          <Card size="small" className="p-5">
            <Statistic title={el?.title} value={el?.count} />
          </Card>
        </Col>
      ))}

      <ModalFormCreator
        loading={api.isLoading}
        open={editData !== null}
        onCreate={formIndex == 0 ? editUserInfoHandler : editPlanHandler}
        onCancel={() => {
          setEditData(null);
        }}
        menuFields={
          CONSTANTS.FORM_FIELD?.[
          formIndex == 0 ? "USER_INFO_MODAL" : "USER_PLAN_MODAL"
          ]
        }
        formData={editData}
        name={formIndex == 0 ? "Edit User Info" : "Edit User Plan"}
        SubmitName={"Update"}
      />

      {/* //logo favicon -tooltip edit niche half half
     plan user niche 4 line
    ma api call thy eno time ..badhu mli ley */}
    </>
  );
};

export default ProjectCards;
