import {
  AiOutlineSetting,
  AiOutlineUser,
} from "react-icons/ai";
import { CiCreditCard2, CiImageOn } from "react-icons/ci";
import { IoAnalyticsOutline } from "react-icons/io5"
import { VscGraph } from "react-icons/vsc";
import { RiBillLine } from "react-icons/ri";
import { HiLanguage, HiOutlineUserGroup, HiOutlineWrench } from "react-icons/hi2";
import { GrConnect, GrDocumentConfig, GrManual } from "react-icons/gr";
import { MdHorizontalDistribute, MdOutlineFeedback, MdOutlinePriceChange } from "react-icons/md";
const data = [
  {
    id: "dashboard",
    icon: <VscGraph />,
    label: "Dashboard",
  },
  {
    id: "billAnalysis",
    icon: <IoAnalyticsOutline />,
    label: "Bill Analysis",
  },
  // {
  //   id: "dashboard",
  //   icon: <AiOutlineDashboard />,
  //   label: "Dashboard",
  // },
  {
    id: "connectedproject",
    icon: <GrConnect />,
    label: "Connected Project",
  },
  {
    id: "users",
    icon: <AiOutlineUser />,
    label: "Users",
  },
  {
    id: "plan",
    icon: <MdOutlinePriceChange />,
    label: "Plan",
  },
  {
    id: "language",
    icon: <HiLanguage />,
    label: "Language",
  },
  {
    id: "asset",
    icon: <CiImageOn />,
    label: "Asset",
  },
  {
    id: "feedback",
    icon: <MdOutlineFeedback />,
    label: "Feedback",
  },
  {
    id: "request",
    icon: <HiOutlineUserGroup />,
    label: "Feature Request",
  },
  // {
  //   id: "clubs",
  //   icon: <GiMeshBall />,
  //   label: "Clubs",
  // },
  // {
  //   id: "request",
  //   icon: <HiOutlineUserGroup />,
  //   label: "Request",
  // },
  // {
  //   id: "bouncers",
  //   icon: <RiCustomerService2Fill />,
  //   label: "Bouncers",
  // },
  // {
  //   id: "valets",
  //   icon: <GiCarKey />,
  //   label: "Valets",
  // },
  // {
  //   id: "feed",
  //   icon: <HiOutlineSquares2X2 />,
  //   label: "Feed",
  // },
  // {
  //   id: "events",
  //   icon: <RiCalendarEventFill />,
  //   label: "Events",
  // },


  // {
  //   id: "billing",
  //   icon: <RiBillLine />,
  //   label: "Billing",
  // },


  {
    id: "pricing",
    icon: <GrDocumentConfig />,
    label: "Pricing Config",
    subMenu: [
      {
        id: "billing",
        icon: <RiBillLine />,
        label: "Billing",
      },
      {
        id: "distributor-partner",
        icon: <MdHorizontalDistribute />,
        label: "Distributor Partner",
      },
      {
        id: "approved-credit",
        icon: <CiCreditCard2 />,
        label: "Approved Credit",
      },
    ],
  },
  {
    id: "billmanually",
    icon: <GrManual />,
    label: "Manually bill",
  },
  {
    id: "maintenance",
    icon: <HiOutlineWrench />,
    label: "Maintenance",
  },
  {
    id: "setting",
    icon: <AiOutlineSetting />,
    label: "Setting",
  },

  // {
  //   id: "employee",
  //   icon: <MdPeopleOutline />,
  //   label: "Employee",
  // },
  // {
  //   id: "logBook",
  //   icon: <GoBook />,
  //   label: "Log Book",
  //   subMenu: [
  //     {
  //       id: "log",
  //       icon: <RxActivityLog />,
  //       label: "Log",
  //     },
  //     {
  //       id: "ABTMeter",
  //       icon: <CgFileDocument />,
  //       label: "ABT Meter",
  //     },
  //     {
  //       id: "cleaningLog",
  //       icon: <TbFileStack />,
  //       label: "Cleaning Log",
  //     },
  //   ],
  // },
  // {
  //   id: "plantDetails",
  //   icon: <AiOutlineSetting />,
  //   label: "Plant Details",
  //   subMenu: [
  //     {
  //       id: "details",
  //       icon: <SlSettings />,
  //       label: "Details",
  //     },
  //     {
  //       id: "OMChecklist",
  //       icon: <VscChecklist />,
  //       label: "O&M Checklist",
  //     },
  //     {
  //       id: "setting",
  //       icon: <AiOutlineSetting />,
  //       label: "Setting",
  //     },
  //   ],
  // },
];
export default data;
