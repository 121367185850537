import React from "react";
import { Outlet } from "react-router-dom";

const DynamicUserLayout = (prop) => {
  // const [Data ,setData] = useState({})
  // const API = useHttp();
  //   useEffect(() => {

  //     console.log("object GEt ME Data");
  //   }, []);
  return (
    <>
      {/* <div className="mt-6"></div> */}
      <Outlet />
    </>
  );
};

export default DynamicUserLayout;
