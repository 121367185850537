import { measureTextWidth, Pie } from '@ant-design/plots';
import React from 'react';
import { Empty, Spin } from 'antd';
import { formatAmount } from '../../util/functions';
// import CONSTANTS from '../../util/constant/CONSTANTS';

const DonutChart = ({
    data = [],
    // label = "chart",
    isLoading = false,
    // annotations = [],
}) => {
    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const totalValue = data.reduce((acc, item) => acc + item.value, 0);
    const config = {
        data,
        // color: ({ name }) => CONSTANTS.UTILIZATION_COLOR?.[name] || "#5B8FF9",
        color: ({ name }) => {
            const item = data.find((d) => d.name === name);
            return item ? item.color : "#a2a2a2";
        },
        angleField: 'value',
        colorField: 'name',
        paddingRight: 80,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-16%',
            text: 'value',
            style: {
                fontWeight: 'bold',
            },
            content:
                ({ value }) => {
                    console.log(value)
                    return `${formatAmount(value)}`;
                },
        },
        statistic: {
            title: {
                offsetY: -20,
                customHtml: (container, view, datum) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = 'Total';
                    return renderStatistic(d, text, {
                        fontSize: 30,
                    });
                },
            },
            content: {
                offsetY: -10,
                style: {
                    fontSize: '32px',
                },
                customHtml: (container, view, datum, data) => {
                    const { width } = container.getBoundingClientRect();
                    const text = `${formatAmount(data.reduce((r, d) => r + d.value, 0))}`;
                    return renderStatistic(width, text, {
                        fontSize: 32,
                    });
                },
            },
        },
        // statistic: {
        //     title: false,
        //     content: {
        //         style: {
        //             fontSize: '16px',
        //             fontWeight: 'bold',
        //             textAlign: 'center',
        //         },
        //         content: () => `
        //             <div style="font-size: 16px; font-weight: bold; text-align: center;">
        //                 <div>Total</div>
        //                 <div>Rs ${formatAmount(totalValue)}</div>

        //             </div>
        //         `,
        //     },
        // },
        annotations: [
            {
                type: 'text',
                style: {
                    text: "name",
                    x: '50%',
                    y: '50%',
                    textAlign: 'center',
                    fontSize: 40,
                    fontStyle: 'bold',
                },
            },
        ],
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },

    };

    if (isLoading) {
        return (
            <div className=" h-96  w-full">
                <Spin className="mt-48" tip="Loading..." size="large">
                    <div className="content" />
                </Spin>
            </div>
        );
    }
    if (data?.length === 0) {
        return (
            <div className=" h-96 flex items-center justify-center w-full">
                <Empty />
            </div>
        );
    }
    return <Pie {...config} />;
};

export default DonutChart;
