import React from 'react'
import BreadCrumb from '../../../component/common/breadCrumb';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';


const items = [
    {
        title: "App",
    },
    {
        title: "Maintenance",
    },
]

const Maintenance = () => {
    return (
        <>
            <BreadCrumb items={items} />
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.MAINTENANCE.getAll,
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                                name: el?.user?.username,
                                rating: el?.rating ? el?.rating : 0,
                                startTime: moment(el?.startTime, "YYYY-MM-DD HH:mm:ss").format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                                endTime: moment(el?.endTime, "YYYY-MM-DD HH:mm:ss").format(
                                    "YYYY-MM-DD HH:mm:ss"
                                ),
                            };
                        });
                    },

                    column: CONSTANTS.TABLE.MAINTENANCE,
                }}
                UPDATE={{
                    API: CONSTANTS.API.MAINTENANCE.update,
                    message: "Updated Maintenance Request successfully",
                    isFormData: false,
                    modaltitle: "Update Maintenance",
                    modalFields: CONSTANTS.FORM_FIELD.MAINTENANCE_MODAL,
                    // payloadModifier: (res) => res,
                }}
                CREATE={{
                    API: CONSTANTS.API.MAINTENANCE.craete,
                    message: "Created Maintenance Successfully",
                    modaltitle: "Add Maintenance",
                    isFormData: false,
                    modalFields: CONSTANTS.FORM_FIELD.MAINTENANCE_MODAL,
                    // payloadModifier: (res) => res,

                }}
                DELETE={{
                    API: CONSTANTS.API.MAINTENANCE.delete,
                    message: "Deleted Maintenace Request Successfully",
                }}
                isSearch
            />
        </>
    )
}

export default Maintenance