import React from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { Breadcrumb } from "antd";
import CRUDComponent from "../../../component/common/CRUD-Component";
import BreadCrumb from "../../../component/common/breadCrumb";

const items=[
  {
    title: "App",
  },
  {
    title: "Language",
  },
]

const Language = () => {
  return (
    <>
    <BreadCrumb items={items} />

      {/* <Breadcrumb
        className="my-6"
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            title: "Language",
          },
        ]}
      /> */}
      <CRUDComponent
        GET={{
          API: CONSTANTS.API.Language.getAll,
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => {
              return {
                ...el,
                no: el?.id,
              };
            });
          },
          column: CONSTANTS.TABLE.LANGUAGE,
        }}
        UPDATE={{
          API: CONSTANTS.API.Language.update,
          message: "Updated Language successfully",
          modaltitle: "Update Language",
          modalFields: CONSTANTS.FORM_FIELD.LANGUAGE_MODAL,
          // payloadModifier: (res) => res,
        }}
        CREATE={{
          API: CONSTANTS.API.Language.craete,
          message: "Created Language Successfully",
          modaltitle: "Add Language",
          modalFields: CONSTANTS.FORM_FIELD.LANGUAGE_MODAL,
          // payloadModifier: (res) => res,
        }}
        DELETE={{
          API: CONSTANTS.API.Language.delete,
          message: "Deleted Successfully",
        }}
        isSearch
      />
    </>
  );
};

export default Language;
