import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import CRUDComponent from "../../../component/common/CRUD-Component";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiGenerator, getMaxUnit } from "../../../util/functions";
import BreadCrumb from "../../../component/common/breadCrumb";
import moment from "moment";

// const filterInitValue = {
//   Alert: "Alert",
//   Success: "Success",
//   Fail: "Fail",
//   Error: "Error",
// };
const items = [
  {
    title: "App",
  },
  {
    title: "Users",
  },
]

const User = (props) => {
  // const activeUser = {};
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState({});
  const [refresh, setRefresh] = useState(false);
  const { userlist } = useParams();
  const location = useLocation();
  // const urlParams = new URLSearchParams(location?.search);
  // console.log(props?.running_Enviroment)

  // const activeUserId = {};
  // Object?.values(props?.running_Enviroment)?.map(detail => {
  //   activeUserId[detail.userId] = true
  // })
  // console.log(activeUserId, "ffd")



  // console.log(activeUserId)

  useEffect(() => {
    const activeUserId = {};
    Object?.values(props?.running_Enviroment)?.map(detail => {
      activeUserId[detail.userId] = true
    })
    setActiveUser(activeUserId);
    setRefresh(true)

  }, [props?.running_Enviroment])
  return (
    <>
      <BreadCrumb items={items} />

      {refresh ? <CRUDComponent
        GET={{
          API: CONSTANTS.API.User.getAll,

          DataModifier: (res, API, Setrefresh) => {

            // console.log(activeUserId, urlParams.get("userlist"), res?.filter(ell => urlParams.get("userlist") === "active" ? activeUser[ell?.id] : urlParams.get("userlist") === "inactive" ? !activeUser[ell?.id] : true))
            return res
              // ?.filter(ell => {
              //   const isActive = activeUser[ell?.id];
              //   const isInactive = !isActive;
              //   const userlistParam = urlParams.get("userlist");
              //   console.log(activeUser)
              //   if (userlistParam === "active" && isActive) {
              //     return true;
              //   } else if (userlistParam === "inactive" && isInactive) {
              //     return true;
              //   } else if (!userlistParam) { // If no specific status set
              //     return true;
              //   }

              //   return false;
              // })
              ?.map((el) => ({
                ...el,
                no: el?.id,
                status: activeUser[el?.id] ? "Active" : "InActive",
                isBlocked: {
                  value: el?.isBlocked,
                  id: el.id,
                  onClick: (id, val) => {
                    const UpdateAPIEnd = apiGenerator(CONSTANTS.API.User.update, {
                      dataId: id,
                    });
                    // { ...CONSTANTS?.API.User.update };
                    // UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    API.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { isBlocked: val },
                      `User ${val ? "blocked" : "Unblocked"} Successfully`
                    );
                  },
                },
                isDomainCreated: {
                  value: el?.isDomainCreated,
                  id: el.id,
                  onClick: (id, val) => {
                    const UpdateAPIEnd = apiGenerator(CONSTANTS.API.User.update, {
                      dataId: id,
                    });
                    // { ...CONSTANTS?.API.User.update };
                    // UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${id}`;
                    API.sendRequest(
                      UpdateAPIEnd,
                      () => {
                        Setrefresh((prev) => !prev);
                      },
                      { isDomainCreated: val },
                      `User ${val ? "DomainActive" : "UnDomainActive"
                      } Successfully`
                    );
                  },
                },
                view: {
                  onClick: () => navigate(`/app/users/${el?.id}`),
                  id: el?.id,
                },
                createdDate: el?.createdAt,
                // createdDate: `${moment(el?.createdAt)?.format("DD-MM-YYYY")} (${getMaxUnit(el?.createdAt)})`,
                viewUser: {
                  onClick: () => {
                    API.sendRequest(apiGenerator(CONSTANTS.API.postUserDashboard, { userId: el?.id }), (res) => {
                      const url = `${process.env.REACT_APP_USER_DASHBOARD_URL}?userToken=${res?.token}`;
                      window.open(url);
                    }, {})
                  },
                  id: el?.id,
                }
              }));
          },
          column: CONSTANTS.TABLE.USER,
        }}
        // UPDATE={{
        //   API: CONSTANTS.API.User.update,
        //   message: "Updated User successfully",
        //   modaltitle: "Update User",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        // CREATE={{
        //   API: CONSTANTS.API.User.craete,
        //   message: "Craeyed User successfully",
        //   modaltitle: "add User",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        DELETE={{
          API: CONSTANTS.API.User.delete,
          message: "Dleted Successfully",
        }}
        FILTERSORTKEY={
          {
            //   isBlocked: "$FCM$",
          }
        }
        isSearch
        props={props}
      /> : null}
    </>
  );
};

export default User;
