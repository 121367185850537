import { Card, Col, Row, Statistic } from "antd";
import useHttp from "../../../hooks/use-http";
import React, { useEffect, useState } from "react";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [data, setData] = useState({});
  const api = useHttp();
  const navigate = useNavigate();
  const DETAILS = [
    {
      title: "User",
      data: [
        { title: "Total User", count: data?.user?.totalUser || 0 , href: "/app/users",},
        {
          title: "Total Active User",
          count: data?.user?.totalActiveUser || 0,
          href: "/app/users",
          
        },
        {
          title: "Total Inactive User",
          count: data?.user?.totalInactiveUser || 0,
          href: "/app/users",
        },
        {
          title: "Total Without Project User",
          count: data?.user?.totalUserWithoutProject || 0,
          href: "/app/users",
        },
      ],
    },
    {
      title: "Project",
      data: [
        { title: "Total Project", count: data?.project?.totalProject || 0 },
        {
          title: "Total Active Project",
          count: data?.project?.totalActiveProject || 0,
        },
        {
          title: "Total Inactive Project",
          count: data?.project?.totalInactiveProject || 0,
        },
      ],
    },
    {
      title: "Service",
      data: [
        { title: "Total Services", count: data?.service?.totalServices || 0 },
        {
          title: "Total Active Services",
          count: data?.service?.totalActiveServices || 0,
        },
        {
          title: "Total Inactive Services",
          count: data?.service?.totalInactiveServices || 0,
        },
      ],
    },
    {
      title: "Environment",
      data: [
        {
          title: "Total Environment",
          count: data?.environment?.totalEnvironmentServices || 0,
        },
        {
          title: "Total Active Environment",
          count: data?.environment?.totalActiveEnvironment || 0,
        },
        {
          title: "Total Inactive Environment",
          count: data?.environment?.totalInactiveEnvironment || 0,
        },
      ],
    },
  ];

  useEffect(() => {
    api.sendRequest(CONSTANTS.API.Analytics, (res) => {
      setData(res?.data);
    });
  }, []);
  return (
    <Row gutter={[16, 16]} className="mt-6 mb-6">
      {DETAILS?.map((el) => (
        <>
          <Col span={24} className="ml-4">
            <p className="text-2xl font-semibold">{el?.title}</p>
          </Col>
          {el?.data?.map((ell) => (
            <Col span={8} xs={24} sm={12} md={8} lg={8} xl={8}>
              <Card
               hoverable={ell?.href}
                size="small"
                className={`p-5 ${ell?.href ? "cursor-pointer" : ""}`}
                onClick={() => ell?.href && navigate(`${ell?.href}`)}
              >
                <Statistic
                  title={ell?.title}
                  value={ell?.count}
                  // formatter={(value) => <CountUp end={value} separator="," />}
                />
                {/* <div>
              <p className="font-semibold text-lg text-slate-400">
                {el?.title}
              </p>

              <p className="text-xl font-medium">{el?.count}</p>
            </div> */}
              </Card>
            </Col>
          ))}
        </>
      ))}
    </Row>
  );
};

export default Dashboard;
