import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../component/common/breadCrumb';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import useHttp from '../../../hooks/use-http';
import { Button, Popconfirm } from 'antd';
import {
    CheckCircleTwoTone,
} from "@ant-design/icons";


const items = [
    {
        title: "App",
    },
    {
        title: "Bill Collect",
    },
]

const paymentStatus = {
    0: <span className="text-[#ffa500]">Pending</span>,
    1: <span className="text-green-600">Success</span>,
    3: <span className="text-[#ff4016]">Failed</span>,
    // 3: "Pending",

};

const ManualBillCollect = () => {
    const [userDetail, setUserDetail] = useState([]);
    const api = useHttp();

    useEffect(() => {
        const field = CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL.find(
            (fil) => fil.id === "userId"
        );
        if (field?.option?.length) {
            return;
        }
        api.sendRequest(CONSTANTS.API.User.getAll, (res) => {

            setUserDetail(res?.data?.data)
            const options = res?.data?.rows?.filter(el => el?.username !== null)?.map((user) => ({
                id: user?.id,
                label: `${user?.username}`,
                value: user?.id,
            }));
            // console.log(options);
            field.option = options;

        });

    }, []);

    const onAccept = (data) => {
        const { billId, userId, Setrefresh } = data;
        const payload = {
            billId,
            userId
        }

        api.sendRequest(CONSTANTS.API.User.createBillDone, (res) => {
            Setrefresh(prev => !prev)
        }, payload, `Bill Paid Successfully`);
    }
    return (
        <>
            <BreadCrumb items={items} />
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.Billing.getAll,
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                                name: el?.user?.username,
                                email: el?.user?.email,
                                paymentStatus: el?.isPaid,
                                isPaid: paymentStatus[el?.isPaid],
                                isbillpaid: {
                                    id: el?.id,
                                    isPaid: el?.isPaid ? "success" : "Pending",
                                    onAccept: () => {
                                        const data = {
                                            billId: el?.id,
                                            userId: el?.userId,
                                            API,
                                            Setrefresh,

                                        }
                                        onAccept(data)
                                    }
                                }

                            };
                        });
                    },

                    column: CONSTANTS.TABLE.BILLMANUAL,
                }}
                // UPDATE={{
                //     API: CONSTANTS.API.FEEDBACK.update,
                //     message: "Updated Feedback successfully",
                //     isFormData: false,
                //     modaltitle: "Update Feedback",
                //     modalFields: CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL,
                //     // payloadModifier: (res) => res,
                // }}
                // CREATE={{
                //   API: CONSTANTS.API.FEEDBACK.craete,
                //   message: "Created Feedback Successfully",
                //   modaltitle: "Add Feedback",
                //   isFormData: false,
                //   modalFields: CONSTANTS.FORM_FIELD.FEEDBACK_CREATE_MODAL,
                //   // payloadModifier: (res) => res,

                // }}
                // DELETE={{
                //     API: CONSTANTS.API.FEEDBACK.delete,
                //     message: "Deleted Feedback Successfully",
                // }}
                isSearch
            />
        </>
    )
}

export default ManualBillCollect