import { Breadcrumb } from 'antd';
import React from 'react'

const BreadCrumb = (props) => {
    const {extraBelow=<></>, items=[], } = props;
  return (
    <>
    <div className="flex justify-between items-center">
        <Breadcrumb
          className="mt-5"
          separator=" > "
          items={items}
        />
        {extraBelow}
      </div>
    </>
  )
}

export default BreadCrumb