import React, { useState } from 'react'
import CRUDComponent from '../../../../component/common/CRUD-Component';
import CONSTANTS from '../../../../util/constant/CONSTANTS';
import moment from 'moment';
import ModalFormCreator from '../../../../component/common/ModalFormCreator';
import useHttp from '../../../../hooks/use-http';
import { apiGenerator } from '../../../../util/functions';
import {
    EyeOutlined,
} from "@ant-design/icons";
import { Button, Modal } from 'antd';

const Request = () => {
    const api = useHttp();
    const [updateOpen, setUpdateOpen] = useState(null);
    const [modalData, setModalData] = useState(null)
    const [detailView, setDetailView] = useState(null)


    const updateConfirmHandler = (payload) => {
        api.sendRequest(apiGenerator(CONSTANTS.API.CreditApproved.update, { dataId: updateOpen }), (res) => {
        }, payload);
    };

    // Rejection Modal Handler 
    const modalSubmitHandler = (value) => {
        const payload = {
            ...modalData,
            ...value
        }
        requestHandler(payload);
    }

    // Request Handler
    const requestHandler = (data) => {
        const { isApproved, notes, dataId, Setrefresh } = data;

        const payload = {
            status: isApproved ? 'Active' : 'Reject',
            notes
        }
        const REQUEST_API = apiGenerator(CONSTANTS.API.CreditApproved.updateStatus, { dataId });
        console.log(REQUEST_API)
        api.sendRequest(REQUEST_API, () => {
            Setrefresh(prev => !prev)
            setModalData(null)
        }, payload, `Coupon Request  ${isApproved ? 'Approved' : 'Rejected'}`)

    }


    return (
        <>
            <ModalFormCreator
                loading={api.isLoading}
                open={modalData !== null}
                onCreate={modalSubmitHandler}
                modalWidth={500}
                onCancel={() => {
                    setModalData(null);
                }}
                menuFields={CONSTANTS.FORM_FIELD.REJECT_REQUEST_MODAL}
                name={"Write Reason for Rejection"}
            />
            <Modal
                title={<p className='text-black'>Reason</p>}
                open={detailView !== null}
                centered
                onCancel={() => setDetailView(null)}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
            >
                <p className='text-black text-base'>{detailView?.reason}</p>
            </Modal>
            {/* <ModalFormCreator
                loading={api.isLoading}
                open={updateOpen !== null}
                onCreate={updateConfirmHandler}
                onCancel={() => {
                    setUpdateOpen(null);
                }}
                // menuFields={CREATE?.modalFields}
                menu="REJECT_REQUEST_MODAL"
                formData={{}}
                name="Delete Credit"
                SubmitName="Submit"
            /> */}
            <CRUDComponent
                GET={{

                    API: CONSTANTS.API.CreditApproved.getAll,
                    extraQuery: { status: "1" },
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: `WOOFF${el?.id?.toString().padStart(6, "0")}`,
                                activeDate: moment(el?.activeDate)?.format("DD-MM-YYYY HH:mm:ss"),
                                createdAt: moment(el?.createdAt)?.format("DD-MM-YYYY HH:mm:ss"),
                                email: el?.user?.email,
                                distributer: el?.distributer?.username,
                                amount: el?.credits,
                                view: {
                                    id: el?.id,
                                    onClick: () => {
                                        setDetailView({
                                            reason: el?.reason
                                        })
                                    }
                                },
                                request: {
                                    id: el?.id,
                                    onAccept: () => {
                                        const data = {
                                            dataId: el?.id,
                                            API,
                                            Setrefresh,
                                            isApproved: true
                                        }
                                        requestHandler(data)
                                    },
                                    onReject: () => {

                                        setModalData({
                                            dataId: el?.id,
                                            API,
                                            Setrefresh,
                                            isApproved: false,
                                        })
                                    },
                                },
                                // acceptor: <Button onClick={() => setUpdateOpen(el?.id)} type="primary" className="textcolor">
                                //     <EyeOutlined />
                                // </Button>,
                            };
                        });
                    },
                    column: CONSTANTS.TABLE.REQUESTHISTORY,
                }}
                UPDATE={{
                    API: CONSTANTS.API.CreditApproved.update,
                    message: "Updated Pricing successfully",
                    modaltitle: "Update Pricing",
                    modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                    // payloadModifier: (res) => res,
                }}
                // CREATE={{
                //     API: CONSTANTS.API.CreditApproved.create,
                //     message: "Created Pricing Successfully",
                //     modaltitle: "Add Pricing",
                //     modalFields: CONSTANTS.FORM_FIELD.PRICINGCONFIG_MODAL,
                //     // payloadModifier: (res) => res,
                // }}
                // DELETE={{
                //     API: CONSTANTS.API.CreditApproved.delete,
                //     message: "Deleted Successfully",
                // }}
                isSearch
            />
        </>
    )
}

export default Request