import React from "react";
import CRUDComponent from "../../../../../component/common/CRUD-Component";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import BreadCrumb from "../../../../../component/common/breadCrumb";



const ProjectId = () => {
  const param = useParams();
  const navigate = useNavigate();
  const items=[
    {
      title: "App",
    },
    {
      href: `/app/users/`,
      title: "Users",
    },
  
    {
      href: `/app/users/${param.userId}/`,
      title: "Project",
    },
  
    {
      title: "Service",
    },
  ]
  return (
    <>
        <BreadCrumb items={items} />

      {/* <Breadcrumb
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            href: `/app/users/`,
            title: "Users",
          },

          {
            href: `/app/users/${param.userId}/`,
            title: "Project",
          },

          {
            title: "Service",
          },
        ]}
      /> */}
      <CRUDComponent
        GET={{
          API: CONSTANTS.API.Service.getAll,
          extraQuery: { userId: param.userId, projectId: param.projectId },
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => ({
              ...el,
              no: el?.id,
              view: {
                onClick: () => {
                  navigate(
                    `/app/users/${param.userId}/${param?.projectId}/${el?.id}`
                  );
                },
                no: el?.id,
              },
            }));
          },
          column: CONSTANTS.TABLE.SERVICE,
        }}
        // UPDATE={{
        //   API: CONSTANTS.API.Service.update,
        //   message: "Updated Service successfully",
        //   modaltitle: "Update Service",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        // CREATE={{
        //   API: CONSTANTS.API.Service.craete,
        //   message: "Craeyed Service successfully",
        //   modaltitle: "add Service",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        //   DELETE={{
        //     API: CONSTANTS.API.Service.delete,
        //     message: "Dleted Successfully",
        //   }}
        FILTERSORTKEY={
          {
            //   isBlocked: "$FCM$",
          }
        }
      />
    </>
  );
};

export default ProjectId;
