import React from "react";
import CRUDComponent from "../../../../../../component/common/CRUD-Component";
import { useParams } from "react-router-dom";
import CONSTANTS from "../../../../../../util/constant/CONSTANTS";
import { Breadcrumb } from "antd";
import BreadCrumb from "../../../../../../component/common/breadCrumb";

const ServiceId = () => {
  const param = useParams();
  const items=[
    {
      title: "App",
    },
    {
      href: `/app/users/`,
      title: "Users",
    },

    {
      href: `/app/users/${param.userId}/`,
      title: "Project",
    },

    {
      href: `/app/users/${param.userId}/${param?.projectId}/`,
      title: "Service",
    },

    {
      title: "Environment",
    },
  ]
  // const navigate = useNavigate();
  return (
    <>
        <BreadCrumb items={items} />

      {/* <Breadcrumb
        separator=" > "
        items={[
          {
            title: "App",
          },
          {
            href: `/app/users/`,
            title: "Users",
          },

          {
            href: `/app/users/${param.userId}/`,
            title: "Project",
          },

          {
            href: `/app/users/${param.userId}/${param?.projectId}/`,
            title: "Service",
          },

          {
            title: "Environment",
          },
        ]}
      /> */}
      <CRUDComponent
        GET={{
          API: CONSTANTS.API.Environment.getAll,
          extraQuery: {
            serviceId: param.serviceId,
          },
          DataModifier: (res, API, Setrefresh) => {
            return res?.map((el) => ({
              ...el,
              no: el?.id,
              // view: {
              //   onClick: () => {
              //     navigate(
              //       `/app/users/${param.userId}/${param?.projectId}/${param.serviceId}/${el?.id}`
              //     );
              //   },
              //   id: el?.id,
              // },
            }));
          },
          column: CONSTANTS.TABLE.ENVIRONMENT,
        }}
        // UPDATE={{
        //   API: CONSTANTS.API.Environment.update,
        //   message: "Updated Environment successfully",
        //   modaltitle: "Update Environment",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        // CREATE={{
        //   API: CONSTANTS.API.Environment.craete,
        //   message: "Craeyed Environment successfully",
        //   modaltitle: "add Environment",
        //   modalFields: CONSTANTS.FORM_FIELD.TECHNOLOGY_MODAL,
        //   // payloadModifier: (res) => res,
        // }}
        //   DELETE={{
        //     API: CONSTANTS.API.Environment.delete,
        //     message: "Dleted Successfully",
        //   }}
        FILTERSORTKEY={
          {
            //   isBlocked: "$FCM$",
          }
        }
      />
    </>
  );
};

export default ServiceId;
