import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../component/common/breadCrumb';
import CRUDComponent from '../../../component/common/CRUD-Component';
import CONSTANTS from '../../../util/constant/CONSTANTS';
import moment from 'moment';
import DonutChart from '../../../component/chart/donut-chart';
import { Card, Col, DatePicker, Row } from 'antd';
import BarChart from '../../../component/chart/bar-charts';
import useHttp from '../../../hooks/use-http';
import dayjs from 'dayjs';
import { formatAmount, getStartAndEndDate } from '../../../util/functions';


const items = [
    {
        title: "App",
    },
    {
        title: "Bill Analysis",
    },
];
const paymentStatus = {
    0: <span className="text-[#ffa500]">Pending</span>,
    1: <span className="text-green-600">Success</span>,
    3: <span className="text-[#ff4016]">Failed</span>,
    // 3: "Pending",

};

const BillAnalysis = () => {
    const api = useHttp();
    const [analysis, setAnalysis] = useState({
        "status": "success",
        "data": [
            {
                "totalPaidBills": "121",
                "totalUnpaidBills": "8",
                "totalPaidAmount": 43110.02774241567,
                "totalUnpaidAmount": 53537.19807767868,
                "totalCreditUsed": 3755.04804956913,
                "totalAmount": 96647.22582009436
            }
        ],
        "analysisData": [
            {
                "totalPaidBills": "39",
                "totalUnpaidBills": "4",
                "totalPaidAmount": 0,
                "totalUnpaidAmount": 3766.5899500846863,
                "totalCreditUsed": 0,
                "totalAmount": 3766.5899500846863,
                "month": "2024-08"
            },
            {
                "totalPaidBills": "37",
                "totalUnpaidBills": "4",
                "totalPaidAmount": 0,
                "totalUnpaidAmount": 49770.608127594,
                "totalCreditUsed": 1020.6480007171632,
                "totalAmount": 49770.608127594,
                "month": "2024-07"
            },
            {
                "totalPaidBills": "45",
                "totalUnpaidBills": "0",
                "totalPaidAmount": 43110.02774241567,
                "totalUnpaidAmount": 0,
                "totalCreditUsed": 2734.400048851967,
                "totalAmount": 43110.02774241567,
                "month": "2024-06"
            }
        ]
    });
    const [barChartData, setbarChartData] = useState([]);
    const [extraParams, setExtraParams] = useState({
        startDate: moment().startOf('month').format("YYYY-MM-DD"),
        endDate: moment().endOf('month').format("YYYY-MM-DD")
    });


    useEffect(() => {
        api.sendRequest(CONSTANTS.API.Bill?.getAnalysis, (res) => {
            setAnalysis(res)
        },
            {
                ...extraParams
            })
    }, [extraParams]);

    // const pieData= analysis?.map((el) => ({
    //     name: el?.name,
    //     value: el?.value
    // }))
    const mainData = analysis?.analysisData?.map(aData => {

        return [{
            "name": "Total bill",
            "month": aData?.month,
            "value": +aData?.totalPaidBills,
        }, {
            "name": "Used Credit",
            "month": aData?.month,
            "value": aData?.totalCreditUsed?.toFixed(0),
        }, {
            "name": "Actual Amount",
            "month": aData?.month,
            "value": aData?.totalAmount?.toFixed(0),
        },]
    })?.flat(2)

    const onChange = (date, dateString) => {
        const monthRanges = getStartAndEndDate(dateString);
        // console.log(date, dateString, "jfdhj", monthRanges);
        setExtraParams({
            startDate: monthRanges?.startDate,
            endDate: monthRanges?.endDate,
        })
        // api.sendRequest(CONSTANTS.API.Bill?.getAnalysis, (res) => {
        //     setAnalysis(res)
        // })
    };

    return (
        <>
            <BreadCrumb items={items} />
            {/* <div>
                <DonutChart />
            </div> */}

            <Row gutter={[16, 16]} >
                <Col span={24} className='flex justify-end'>
                    <DatePicker.RangePicker
                        format={"YYYY-MM"}
                        // className="w-[9rem] !border-[#648897] text-[#648897]"
                        onChange={onChange}
                        placeholder="Month"
                        defaultValue={[dayjs(), dayjs()]}
                        // value={date.from && date.to ? [dayjs(date.from), dayjs(date.to)] : [dayjs(), dayjs()]}
                        // suffixIcon={<IoCalendarClearOutline color="#648897" size={20} />}
                        picker="month"
                    />
                </Col>
                <Col span={10} className=''>
                    <Card className='h-full'>
                        <Row>
                            <Col className="text-lg font-semibold" span={24}>Actual Payment</Col>
                            <Col span={24}>
                                <DonutChart isLoading={false} data={[
                                    {
                                        name: "Paid",
                                        id: "paid",
                                        value: +analysis?.data?.[0]?.totalPaidAmount?.toFixed(0),
                                        color: "#62d8a6"
                                    },
                                    {
                                        name: "UnPaid",
                                        id: "unpaid",
                                        value: +analysis?.data?.[0]?.totalUnpaidAmount?.toFixed(0),
                                        color: "#dc2626"
                                    },
                                    // {
                                    //     name: "Total",
                                    //     id: "total",
                                    //     value: +60,
                                    //     // color: +"#cbcbcb" : CONSTANTS?.labelObject[allover]?.color,
                                    // },
                                ]} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={14} className=''>
                    <Card className='h-full'>
                        <BarChart
                            data={analysis?.analysisData?.map(aData => {
                                return [
                                    {
                                        "name": "Total bill",
                                        "month": aData?.month,
                                        "value": +aData?.totalWithoutTax?.toFixed(0),
                                        "group": "Total", // Separate group
                                    },
                                    {
                                        "name": "Used credits",
                                        "month": aData?.month,
                                        "value": +aData?.subtractionFromCredits?.toFixed(0),
                                        color: "#ff8c28",
                                        "group": "Credits", // Separate group
                                    },
                                    {
                                        "name": "Paid bill amount",
                                        "month": aData?.month,
                                        "value": +aData?.totalPaidBill?.toFixed(0),
                                        color: "#62d8a6",
                                        "group": "Bill", // Group together with Unpaid bill
                                    },
                                    {
                                        "name": "Unpaid bill amount",
                                        "month": aData?.month,
                                        "value": +aData?.totalUnPaidBill?.toFixed(0),
                                        color: "#dc2626",
                                        "group": "Bill", // Group together with Paid bill
                                    }
                                ]
                            })?.flat(2)}
                        />

                    </Card>
                </Col>
            </Row>
            <CRUDComponent
                GET={{
                    API: CONSTANTS.API.Bill.getAll,
                    DataModifier: (res, API, Setrefresh) => {
                        return res?.map((el) => {
                            return {
                                ...el,
                                no: el?.id,
                                name: el?.user?.username,
                                email: el?.user?.email,
                                isPaid: paymentStatus[el?.isPaid],
                                amount: `₹ ${formatAmount(el?.totalAfterTax)}`,
                                rating: el?.rating ? el?.rating : 0,
                                date: moment(el?.createdAt)?.format("DD-MMM-YYYY"),
                            };
                        });
                    },

                    column: CONSTANTS.TABLE.BILLANALYSIS,
                }}

                isSearch
            />
        </>
    )
}

export default BillAnalysis