import React from "react";
import { Button, Card, Col, Image, Row, Spin, Tooltip } from "antd";
import moment from "moment";
import DefaultImage from "../../asset/image/dummy-avatar.jpg";
import { apiGenerator } from "../../util/functions";
import CONSTANTS from "../../util/constant/CONSTANTS";

const UserInfoCard = (props) => {
  const { userInfoDetails, setEditData, setFormIndex, userInfo, api } = props;

  return (
    <>
      {" "}
      {/* {api.isLoading ? (
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      ) : ( */}
      <Card className={`px-5 min-h-full ${api?.isLoading ? "h-80" : ""}`}>
        <div className="font-medium text-2xl">User Info</div>
        {api?.isLoading ? (
          <Spin className="mt-20" tip="Loading" size="large">
            <div className="content" />
          </Spin>
        ) : (
          <Row className="mt-5 relative">
            <Col span={18}>
              <div>
                {userInfoDetails?.map((ele) => (
                  <Row key={ele?.id} className="font-medium mt-2 ">
                    <Col span={10} className="text-base">
                      {ele?.label} :
                    </Col>
                    <Col className="text-base flex items-end">
                      &nbsp;&nbsp;&nbsp;{ele?.value}
                    </Col>
                  </Row>
                ))}
                <p className="mt-3">
                  Last Updated on{" "}
                  {moment(userInfo?.updatedAt).format("DD MMM, YYYY hh:mm A")}
                </p>
                <Button
                  onClick={() => {
                    setEditData(userInfo);
                    setFormIndex(0);
                  }}
                  type="primary"
                  className="mt-4"
                >
                  Edit Info
                </Button>
                <Button onClick={() => {
                  api.sendRequest(apiGenerator(CONSTANTS.API.postUserDashboard, { userId: userInfo?.id }), (res) => {
                    const url = `${process.env.REACT_APP_USER_DASHBOARD_URL}?userToken=${res?.token}`;
                    window.open(url);
                  }, {})
                }
                } type="primary" className="sm:ml-2 mt-4">View User Dashboard</Button>
              </div>
            </Col>
            <Col span={6} className="-mt-5 flex items-center justify-center">
              <Row gutter={[12, 12]}>
                <Col
                  span={24}
                  className="flex items-center justify-center gap-2"
                >
                  <Tooltip
                    className={`cursor-pointer `}
                    title={"Organisation Fevicon"}
                  >
                    <Image
                      className="rounded-sm"
                      width={80}
                      src={userInfo?.organizationImage || DefaultImage}
                    />
                  </Tooltip>
                </Col>
                <Col
                  span={24}
                  className="flex items-center justify-center gap-2 flex-col"
                >
                  <Tooltip
                    className={`cursor-pointer `}
                    title={"Organisation Image"}
                  >
                    <Image
                      className="rounded-sm"
                      width={60}
                      src={userInfo?.organizationFavIcon || DefaultImage}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Card>
    </>
  );
};

export default UserInfoCard;
